<template>
  <v-card class="mb-2 mx-auto" max-width="876px" flat >
    <template v-if="!loading">
      <v-row id="menu-area" class="text-right">
        <v-col>
          <v-btn class="mr-2" :loading="loading" color="primary" @click="reload">
            <v-icon left>mdi-reload</v-icon>
            データ更新
          </v-btn>
          <v-btn 
            :loading="loading || pdfLoading" 
            color="primary" 
            @click="generatePDF"
            :disabled="analysisEnqueteAnswer.items.length == 0">
            PDF出力
          </v-btn>
        </v-col>
      </v-row>
      <v-container id="answer-analysis-container" class="mb-2">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="text-h6">{{ analysisEnqueteAnswer.title }}</v-card-title>
              <v-card-subtitle class="text-h6">{{ analysisEnqueteAnswer.answernum }}件の回答</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-for="item in analysisEnqueteAnswer.items" :key="item.itemkey">
          <v-col v-if="item.type === 'textarea'">
            <TextAnswerItem 
              :answerNum="analysisEnqueteAnswer.answernum"
              :item="item" />
          </v-col>
          <v-col v-else-if="item.type === 'radio'">
            <HorizontalBarAnswerItem 
              :answerNum="analysisEnqueteAnswer.answernum"
              :item="item" />
          </v-col>
          <v-col v-else-if="item.type === 'checkboxes'">
            <PieChartAnswerItem 
              :answerNum="analysisEnqueteAnswer.answernum"
              :item="item" />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <!-- アンケート集計中はなにも表示しない -->
    </template>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          アンケートを集計中です。
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mapActions,
  mapState,
} from "vuex";
import {
  GET_ANALYSIS_ENQUETE_ANSWER,
} from "@/store/action-types";
import PieChartAnswerItem from "./components/PieChartAnswerItem.vue";
import HorizontalBarAnswerItem from "./components/HorizontalBarAnswerItem.vue";
import TextAnswerItem from "./components/TextAnswerItem.vue";

import domtoimage from "dom-to-image";
import { PDFDocument, PageSizes } from 'pdf-lib';

export default {
  name: "AnswerTab",
  props: {
    enqueteOpenId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      pdfLoading: false,
    };
  },
  components: {
    PieChartAnswerItem,
    HorizontalBarAnswerItem,
    TextAnswerItem,
  },
  computed: {
    ...mapState({
        analysisEnqueteAnswer: state => state.analysisEnqueteAnswer,
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      getAnalysisEnqueteAnswer: GET_ANALYSIS_ENQUETE_ANSWER,
    }),
    async reload() {
      this.loading = true;
      await this.getAnalysisEnqueteAnswer(this.enqueteOpenId);
      this.loading = false;
    },
    async generatePDF() {
      this.pdfLoading = true;
      // DOM要素を取得
      const answerAnalysisContainer = document.getElementById("answer-analysis-container")
      // dom-to-imageを使用してDOM要素を画像に変換
      let dataURL;
      await domtoimage.toPng(answerAnalysisContainer).then((dataUrl) => {
        // 画像のdataUrlを取得
        dataURL = dataUrl
      });
      // PDFページを作成
      const pdfDoc = await PDFDocument.create();
      let page = "";
      // A4サイズ「width:595.28, height: 841.89」
      // アンケート内容が長い場合、pdfファイルも長さに合わせる
      const blockWidth = answerAnalysisContainer.clientWidth;
      const blockHeight = answerAnalysisContainer.clientHeight;
      if ((blockHeight / blockWidth) > (841.89 / 595.28)) {
        page = pdfDoc.addPage([595.28, 595.28 / (blockWidth / blockHeight)]);
      } else {
        page = pdfDoc.addPage(PageSizes.A4);
      }
      // 画像をPDFに追加
      const pdfImage = await pdfDoc.embedPng(dataURL);
      const { width, height } = page.getSize();
      const drawHeight = width / (blockWidth / blockHeight);
      page.drawImage(pdfImage, {
        x: 0,
        y: height - drawHeight,
        width: width,
        height: drawHeight,
      });

      // PDFをバッファに変換してダウンロード
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = this.analysisEnqueteAnswer.title + '.pdf';
      link.click();

      this.pdfLoading = false;
    },
  },
  async created() {
    this.loading = true;
    await this.getAnalysisEnqueteAnswer(this.enqueteOpenId);
    this.loading = false;
  },
  async mounted() {},
};
</script>