<template>
  <div :class="isSmartPhone ? 'contentsAreaSm' : 'contentsArea'" class="pa-4">
    <v-card class="mx-auto mt-5">
      <v-dialog v-model="startDriveDialog">
        <v-card>
          <v-card-title class="text-h5">位置情報の発信を開始します。よろしいですか？</v-card-title>
          <v-card-text class="breakArea">
            ※走行中は、バス運行画面を閉じずに表示したままにして下さい。<br>
            バス運行画面を閉じた場合、位置情報の発信が終了となります。<br>
            <br>
            尚、位置情報を発信するにあたり、本サービスでは位置情報を発信するにあたり、以下ブラウザでの実行を推奨しております。<br>
            Android端末の場合: Google Chrome<br>
            iOS端末の場合: Safari<br>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="startDriveDialog = false">キャンセル</v-btn>
            <v-btn color="blue darken-1" text @click="startSendLocation()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="endDriveDialog">
        <v-card>
          <v-card-title class="text-h5">位置情報の発信を終了します。よろしいですか？</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="endDriveDialog = false">キャンセル</v-btn>
            <v-btn color="blue darken-1" text @click="endSendLocation()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card-title>
        運転を開始する
      </v-card-title>
      <v-card-text>
        <v-select v-model="selectedBusId" label="送迎バスを選択してください。" :items="busList" item-text="bus_name"
          item-value="bus_id" :disabled="isSending">
        </v-select>
        <v-select v-if="isExistBusRoute" v-model="selectedBusRouteId" label="走行ルートを選択してください。" :items="busRouteListLocal" item-text="bus_route_name"
          item-value="bus_route_id" :disabled="isSending">
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!isSending"
          :disabled="!selectedBusId"
          class="info"
          @click="startDriveDialog = true"
          :loading="loading"
        >運転開始</v-btn>
        <v-btn
          v-else
          color="red"
          @click="endDriveDialog = true"
          :loading="loading"
        >運転終了</v-btn>
      </v-card-actions>
      <v-container>
        <div v-if="loading" class="red--text">
          インターネットに接続されていません。
        </div>
        <div v-if="lastUpdateAt">
          位置情報の最終更新時刻 : {{ covertUnixToYYYYMMDDhhmmss(lastUpdateAt) }}
        </div>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {
  SET_SELECTED_BUS,
  SET_SELECTED_BUS_ROUTE,
  SET_IS_DRIVING,
} from "@/store/mutation-types";
import {
  GET_BUS_ALL,
  GET_BUS_ROUTE_LIST,
  SAVE_BUS_ICON_TO_S3,
  START_SEND_BUS_LOCATION,
  SEND_BUS_LOCATION,
  END_SEND_BUS_LOCATION,
  NOTIFICATION_TEAMS,
  CREATE_BUS_LOG,
  GET_BUS_IS_DRIVING,
} from "@/store/action-types";
import {
  INTERVAL_SEND_LOCATION,
} from '@/constants';

import moment from 'moment';
import GIF from 'gif.js';

export default {
  name: 'BusDriverMenu',
  data() {
    return {
      // 送迎バスの選択肢
      busList: [],
      selectedBusId: null,

      // 走行ルートの選択肢
      busRouteListLocal: [],
      selectedBusRouteId: null,

      // バスアイコン
      busIcon1: require('@/assets/icons/bus-icons/bus-icon1.png'),
      busIcon2: require('@/assets/icons/bus-icons/bus-icon2.png'),

      watchPositionId: null, // 位置情報取得ハンドラー識別ID(位置情報の取得を終了する際に使用)
      intervalSendLocation: INTERVAL_SEND_LOCATION, // バス位置情報送信の間隔
      lastWatchPositionAt: null, // 位置情報の最終取得時刻
      lastUpdateAt: null, // 位置情報の最終送信時刻
      lastCrd: null, // 最終取得位置情報

      isSending: false, // 走行中フラグ
      startDriveDialog: false, // 位置情報送信開始確認ダイアログ
      endDriveDialog: false, // 位置情報送信終了確認ダイアログ

      scheduleId: null, // 定期実行の識別子
      loading: false, // ローディング
    }
  },
  computed: {
    ...mapState({
      isSmartPhone: (state) => state.isSmartPhone,
      busAll: state => state.busAll,
      selectedBus: state => state.selectedBus,
      busRouteList: state => state.busRouteList,
      selectedBusRoute: state => state.selectedBusRoute,
      responseNotificationTeamsBusLocation: state => state.responseNotificationTeamsBusLocation,
      responseSendBusLocation: state => state.responseSendBusLocation,
    }),
    isExistBusRoute() {
      return (this.busRouteListLocal && this.busRouteListLocal.length > 0);
    },
  },
  methods: {
    ...mapMutations({
      setSelectedBus: SET_SELECTED_BUS,
      setSelectedBusRoute: SET_SELECTED_BUS_ROUTE,
      setIsDriving: SET_IS_DRIVING,
    }),
    ...mapActions({
      getBusAll: GET_BUS_ALL,
      getBusRouteList: GET_BUS_ROUTE_LIST,
      saveBusIconToS3: SAVE_BUS_ICON_TO_S3,
      startSendBusLocation: START_SEND_BUS_LOCATION,
      sendBusLocation: SEND_BUS_LOCATION,
      endSendBusLocation: END_SEND_BUS_LOCATION,
      notificationTeams: NOTIFICATION_TEAMS,
      createBusLog: CREATE_BUS_LOG,
      getBusIsDriving: GET_BUS_IS_DRIVING,
    }),
    currentBusName() {
      return (this.busList.find(b => b.bus_id === this.selectedBusId)).bus_name;
    },
    currentDatetime() {
      return String(moment().format("YYYY年 M月 D日 HH:mm:ss"));
    },
    online() {
      // 端末がオンラインの場合
      this.loading = false;
      this.makeSchedule();
    },
    offline() {
      // 端末がオフラインの場合
      this.loading = true;
      this.clearSchedule();
    },
    async makeSchedule() {
      this.busIsDrivingCheck();
      this.scheduleId = setInterval(async () => {
        this.busIsDrivingCheck();
      }, 15000);
    },
    // バックエンドで運転モードが強制終了されていないかチェック
    async busIsDrivingCheck() {
      if (this.scheduleId != null && this.isSending == true) {
        const busIsDriving = await this.getBusIsDriving();
        if (busIsDriving == 0) {
          // バックエンドで運転モードが強制終了されたとき
          // 端末から位置情報取得の処理終了
          navigator.geolocation.clearWatch(this.watchPositionId);
          this.isSending = false; // dataの走行中フラグをfalseに更新
        }
      }
    },
    clearSchedule() {
      clearInterval(this.scheduleId);
      this.scheduleId = null;
    },
    // 位置情報送信開始時
    async startSendLocation() {
      this.startDriveDialog = false; // 位置情報送信開始の確認ダイアログを閉じる
      // 運転開始処理
      this.isSending = true; // dataの走行中フラグをtrueに更新
      await this.startSendBusLocation(); // 走行中フラグを立てる
      function error(err) {
        console.warn('ERROR(' + err.code + '): ' + err.message);
      }
      const id = navigator.geolocation.watchPosition(this.success, error); // 位置情報を取得を開始
      this.watchPositionId = id; // 位置情報取得ハンドラー識別IDを保存
      // Workflows経由でTeamsへ通知
      const title = '[info]送迎バスの運転を開始しました。';
      const text  = `({${this.currentDatetime()}}、{${this.currentBusName()}})`;
      await this.notificationTeams({ title: title, text: text, color: 'default' });
      // バスのログ情報に登録
      await this.createBusLog({ bus_log_type: 'info', bus_log_content: title + text });
    },
    // 位置情報取得成功時
    async success(pos) {
      const now = moment().unix();
      if (!this.lastWatchPositionAt || (now - this.lastWatchPositionAt) > this.intervalSendLocation) { // 位置情報の更新頻度を制限
        const busIsDriving = await this.getBusIsDriving();
        if (busIsDriving == 0) {
          // バックエンドで運転モードが強制終了されたとき
          await this.endSendLocation();
        } else if (busIsDriving == 1) {
          this.lastWatchPositionAt = moment().unix(); // 位置情報の最終取得時刻を更新
          const crd = pos.coords;
          if (crd.latitude !== 0 && crd.longitude !== 0) { // 位置情報が正しく取得できている場合
            try {
              // 位置情報の送信
              let deg = 90;
              if (this.lastCrd) { // 前回取得した位置情報があるなら角度を算出
                deg = this.calcDegByLocations(this.lastCrd.latitude, this.lastCrd.longitude, crd.latitude, crd.longitude);
              }
              const blob = await this.lotateBusIcon(deg); // バスアイコンを回転
              console.log('blob', blob);
              const icon_url = await this.saveBusIconToS3(blob); // S3にアップロード&URLを取得
              const location = {
                lat: crd.latitude,
                lng: crd.longitude,
                icon_url: icon_url
              };
              await this.sendBusLocation(location); // 位置情報を送信
              const hasError = (this.responseSendBusLocation.name === "AxiosError");
              if (hasError) {
                // Workflows経由でTeamsへ通知
                const title = '[error(browser)]位置情報の更新に失敗しています。';
                const text = `運転モードを終了します。再開する場合は送迎バスから運転開始して下さい。({${this.currentDatetime()}}、{${this.currentBusName()}}`;
                await this.notificationTeams({ title: title, text: text, color: 'error' });
                // バスのログ情報に登録
                await this.createBusLog({ bus_log_type: 'error', bus_log_content: title + text });
                // 運転終了処理
                await this.endSendLocation();

              } else {
                this.lastUpdateAt = moment().unix(); // 位置情報の最終取得時刻を更新
                this.lastCrd = crd; // 最終取得位置情報を更新
                // Workflows経由でTeamsへ通知
                const title = '[info]送迎バスが走行中です。';
                const text = `({${this.currentDatetime()}}、{${this.currentBusName()}}、現在地：{${crd.latitude}}.{${crd.longitude}})`;
                await this.notificationTeams({ title: title, text: text, color: 'default' });
                // バスのログ情報に登録
                await this.createBusLog({ bus_log_type: 'info', bus_log_content: title + text });
              }
            } catch (error) {
              console.error(error);
              // Workflows経由でTeamsへ通知
              const title = '[error(browser)]位置情報の更新に失敗しています。';
              const text = `運転モードを終了します。再開する場合は送迎バスから運転開始して下さい。({${this.currentDatetime()}}、{${this.currentBusName()}}`;
              await this.notificationTeams({ title: title, text: text, color: 'error'});
              // バスのログ情報に登録
              await this.createBusLog({ bus_log_type: 'error', bus_log_content: title + text });
              // 運転終了処理
              await this.endSendLocation();
            }
          }
        }
      }
    },
    calcDegByLocations(lat1, lng1, lat2, lng2) {
      // 2地点間の方位角を算出(北を0度とする359度までの角度)
      const y1 = lat1 * Math.PI / 180; // 緯度1をラジアンに変換
      const y2 = lat2 * Math.PI / 180; // 緯度2をラジアンに変換
      const Δx = (lng2 - lng1) * Math.PI / 180; // 経度の差をラジアンに変換
      const y = Math.sin(Δx)
      const x = Math.cos(y1) * Math.tan(y2) - Math.sin(y1) * Math.cos(Δx);
      let ϕ = Math.atan2(y, x) * 180 / Math.PI; // -180から180の範囲で返される
      if (ϕ < 0) {
        ϕ += 360; // 0から360の範囲に変換
      }
      return ϕ;
    },
    async lotateBusIcon(deg) {
      console.log('lotateBusIcon', deg)

      const canvas1 = await new Promise((resolve) => {
        const img = new Image();
        img.onload = async function () {
          // Canvasを作成
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d', {willReadFrequently: true});
          // Canvasのサイズを画像のサイズに合わせる
          canvas.width = img.width;
          canvas.height = img.height;
          // 画像1をCanvasに描画
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.save();
          if (180 <= deg && deg < 360) { // バスが走行しているように見えるよう、画像反転
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((deg - 180) * (Math.PI / 180));
            ctx.scale(1, -1); // Y軸方向に反転
            ctx.drawImage(img, -canvas.width / 2, -canvas.height / 2);
          } else {
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((deg * Math.PI) / 180);
            ctx.drawImage(img, (-canvas.width / 2), (-canvas.height / 2));
          }
          ctx.restore();
          resolve(canvas);
        }
        img.src = this.busIcon1;
      })
      console.log('canvas1', canvas1)

      const canvas2 = await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = async function () {
          // Canvasを作成
          const canvas = document.createElement('canvas', {willReadFrequently: true});
          const ctx = canvas.getContext('2d');

          // Canvasのサイズを画像のサイズに合わせる
          canvas.width = img.width;
          canvas.height = img.height;

          // 画像1をCanvasに描画
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.save();
          if (180 <= deg && deg < 360) { // バスが走行しているように見えるよう、画像反転
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((deg - 180) * (Math.PI / 180));
            ctx.scale(1, -1); // Y軸方向に反転
            ctx.drawImage(img, -canvas.width / 2, -canvas.height / 2);
          } else {
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((deg * Math.PI) / 180);
            ctx.drawImage(img, (-canvas.width / 2), (-canvas.height / 2));
          }
          ctx.restore();
          resolve(canvas);
        }
        img.onerror = function () {
          reject('画像の読み込みに失敗しました。');
        };
        img.src = this.busIcon2;
      })
      console.log('canvas2', canvas2)

      return new Promise((resolve) => {
        const gif = new GIF({
          workers: 2,
          quality: 10,
          transparent: "0x000000"
        });
        gif.addFrame(canvas1, {delay: 350});
        gif.addFrame(canvas2, {delay: 350});
        gif.on('finished', function(blob) {
          console.log('pre blob', blob)
          resolve(blob);
        });
        gif.render();
      })
    },
    base64Encode(...parts) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => {
          const offset = reader.result.indexOf(",") + 1;
          resolve(reader.result.slice(offset));
        };
        reader.readAsDataURL(new Blob(parts));
      });
    },
    async convertDataUrlToBlob(dataUrl) {
      return await (await fetch(dataUrl)).blob();
    },
    // 位置情報送信終了時
    async endSendLocation() {
      this.endDriveDialog = false; // 位置情報送信終了の確認ダイアログを閉じる
      const busIsDriving = await this.getBusIsDriving();
      // 端末から位置情報取得の処理終了
      navigator.geolocation.clearWatch(this.watchPositionId);
      if (busIsDriving == 1) {
        // 端末から位置情報取得の処理終了
        navigator.geolocation.clearWatch(this.watchPositionId);
        // Workflows経由でTeamsへ通知
        const title = '[info]送迎バスの運転を終了しました。';
        const text = `({${this.currentDatetime()}}、{${this.currentBusName()}})`;
        await this.notificationTeams({ title: title, text: text, color: 'default' });
        // バスのログ情報に登録
        await this.createBusLog({ bus_log_type: 'info', bus_log_content: title + text });
        // DBの走行中フラグを折る
        await this.endSendBusLocation();
      }
      this.isSending = false; // dataの走行中フラグをfalseに更新
    },
    covertUnixToYYYYMMDDhhmmss(unixTimestamp) {
      const date = new Date(unixTimestamp * 1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  },
  watch: {
    selectedBusId: function (newVal) {
      const busInfo = this.busList.find(bus => bus.bus_id === newVal);
      this.setSelectedBus(busInfo);
    },
    selectedBusRouteId: function (newVal) {
      const busRouteInfo = this.busRouteListLocal.find(route => route.bus_route_id === newVal);
      this.setSelectedBusRoute(busRouteInfo);
    },
    isSending: function (newVal) {
      this.setIsDriving(newVal);
      if (newVal == true) {
        this.makeSchedule();
      } else {
        this.clearSchedule();
      }
    },
  },
  async created() {
    // 送迎バスの配列を作成
    await this.getBusAll(); // バスの一覧取得
    const busList = this.busAll.filter(bus => (bus.is_delete === 0) && (bus.is_driving === 0))
    this.busList = [...busList];
    // 走行ルートの配列を作成
    await this.getBusRouteList(); // 走行ルートの一覧取得
    this.busRouteListLocal = structuredClone(this.busRouteList);
  },
  async mounted() {
    // ネットワーク状態を検知するイベントリスナー
    window.addEventListener('online', this.online);
    window.addEventListener('offline', this.offline);
    
    // 画面の自動スリープ制御
    let wakeLock = null; // WakeLockSentinel object を格納する変数
    const requestWakeLock = async () => { // screen wake lock をリクエストするための関数
      try { // ブラウザは Screen Wake Lock を拒否することがあるので、try...catch を使い拒否された場合の処理も記述する
        wakeLock = await navigator.wakeLock.request('screen');
        wakeLock.addEventListener('release', () => { // Screen Wake Lock がリリースされたときの処理
          console.log('Screen Wake Lock was released');
        });
        console.log('Screen Wake Lock is active');

      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    };
    await requestWakeLock(); // screen wake lock をリクエストする

    // 画面非表示イベント
    document.onvisibilitychange = async() => {
      if (document.visibilityState === 'hidden' && this.loading == false) {
        if (!!this.selectedBusId && this.isSending) {
          const busIsDriving = await this.getBusIsDriving();
          if (busIsDriving == 1) {
            // Workflows経由でTeamsへ通知
            // const text = `[alert]走行中に画面非表示操作が行われました。再開する場合は送迎バスから運転開始して下さい。({${this.currentDatetime()}}、{${this.currentBusName()}})`;
            const title = '[alert]画面を非表示にする操作が行われました。';
            const text = '以下の操作が行われた可能性があります。\r\n・ブラウザバック\r\n・ブラウザ終了\r\n・スリープ';
            await this.notificationTeams({ title: title, text: text, color: 'warning' });
            // バスのログ情報に登録
            await this.createBusLog({ bus_log_type: 'alert', bus_log_content: title + '\r\n' + text });
          }
          // 運転終了処理
          await this.endSendLocation();
        }
      }
    };

    // beforeunloadイベント
    window.addEventListener('beforeunload', async(event) => {
      console.log('event', event)
      if (!!this.selectedBusId && this.isSending && this.loading == false) {
        const busIsDriving = await this.getBusIsDriving();
        if (busIsDriving == 1) {
          // 確認ダイアログの表示
          event.preventDefault();
          event.returnValue = '';

          // Workflows経由でTeamsへ通知
          let navigationType = window?.performance?.getEntriesByType("navigation")[0].type;
          let title = '';
          let text = 'beforeunload';
          switch (navigationType) {
            case "navigate":
              console.log(navigationType + " : ページ遷移");
              // text = `navigate`;
              title = '[alert]画面を非表示にする操作が行われました。';
              text = '以下の操作が行われた可能性があります。\r\n・ブラウザバック\r\n・ブラウザ終了\r\n・スリープ';
              break;
            
            case "reload":
              console.log(navigationType + " : ページ更新");
              // text = `[alert]走行中に画面リロード操作が行われました。再開する場合は送迎バスから運転開始して下さい。({${this.currentDatetime()}}、{${this.currentBusName()}})`;
              title = '[alert]画面を非表示にする操作が行われました。';
              text = '以下の操作が行われた可能性があります。\r\n・ブラウザバック\r\n・ブラウザ終了\r\n・スリープ';
              break;
            
            case "back_forward":
              console.log(navigationType + " : 戻る・進む");
              // text = `[alert]走行中にブラウザバックが行われました。再開する場合は送迎バスから運転開始して下さい。({${this.currentDatetime()}}、{${this.currentBusName()}})`;
              title = '[alert]画面を非表示にする操作が行われました。';
              text = '以下の操作が行われた可能性があります。\r\n・ブラウザバック\r\n・ブラウザ終了\r\n・スリープ';
              break;
            
            case "prerender":
              console.log(navigationType + " : prerender");
              // text = `prerender`;
              title = '[alert]画面を非表示にする操作が行われました。';
              text = '以下の操作が行われた可能性があります。\r\n・ブラウザバック\r\n・ブラウザ終了\r\n・スリープ';
              break;
          }
          await this.notificationTeams({ title: title , text: text, color: 'warning' });

          // バスのログ情報に登録
          await this.createBusLog({ bus_log_type: 'alert', bus_log_content: title + '\r\n' + text });

        }
        // 運転終了処理
        await this.endSendLocation();
      }
    });
  },
}
</script>