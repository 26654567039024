<template>
    <div class="preview-container">
      <div class="line-header">
        <div class="line-toolbar">
          <v-row cols="12" class="px-2 pt-3 pb-3">
            <v-col cols="10" class="text-center pl-16">
              <div>プレビュー</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-icon color="white">mdi-close-circle-outline</v-icon>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="content">
        <v-container class="talk-content">
          <template>
            <TalkRendering :preview="preview" :title="title" :messages="messages" :positionInfo="positionInfo" ref="talkRendering" :url="url"/>
          </template>
        </v-container>
      </div>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import TalkRendering from "../TalkRendering/index.vue";
  
  export default Vue.extend({
    components: { TalkRendering },
    props: {
      messages: Array,
      positionInfo: Array,
      preview: Boolean,
      title: String,
      url: Array,
    },
    computed: {},
    methods: {},
    watch: {
      preview: function(value) {
        if (value) {
          this.$forceUpdate();
        }
      }
    }
  });
  </script>
  
  <style lang="less">
  .preview-container .content {
    max-width: 320px;
    width: 320px;
    height: 480px;
    overflow-y: auto;
    background: #666f86;
  }
  
  .line-header {
    background: white;
  }
  
  .line-toolbar {
    background-color: #353a3f;
    div {
      color: white;
    }
  }
  
  .talk-content {
    padding: 5px !important;
    background-color: #666f86;
  }
  </style>
  