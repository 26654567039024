<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <PieChartBase ref="chartCanvas" :chartData="dataSet" :options="options" :height="250" />
</template>

<script>
import Vue from "vue";
import PieChartBase from "@/charts/PieChartBase";
import style from "@/mixins/style";
import 'chartjs-plugin-datalabels';

export default Vue.extend({
  name: "PieChart",
  components: { PieChartBase },
  props: {
    dataSet: {
      type: Object,
    },
    // labels: {
    //   type: Array,
    // },
  },
  mixins: [style],
  data() {
    return {
      options: {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const kazu = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
              const kazuList = data.datasets[tooltipItem.datasetIndex].data
              let sum = 0
              for (let i = 0; i < kazuList.length; i++) {
                sum += kazuList[i]
              }
              let warizan = 0
              if (sum != 0 && kazu != 0) {
                warizan = kazu / sum
              }
              return data.labels[tooltipItem.index] + ' ' + kazu + '件 ' + Math.round(warizan * 100) + '%';
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: { // 凡例の位置、スタイル
          position: "right",
          labels: {
            usePointStyle: true,
            // fontSize: 28,
            generateLabels: function (data) {
              // 凡例の表示
              const chartData = data.tooltip._data;
              const chartDataset = chartData.datasets[0];
              const chartLabels = chartData.labels;
              const legendArray = [];
              for (let i = 0; i < chartLabels.length; i++) {
                const eachLabel = chartLabels[i];
                const eachData = chartDataset.data[i];
                const backgroundColor = chartDataset.backgroundColor[i];
                const eachLengend = {
                  // 表示されるラベル
                  text: `${eachLabel}  ${eachData}件`,
                  // 凡例ボックスの塗りつぶしスタイル
                  fillStyle: backgroundColor,
                  //  trueの場合、この項目は非表示のデータセットを表します。ラベルは取り消し線を伴ってレンダリングされます
                  hidden: false,
                  // ボックス枠用。次をご覧ください。https://developer.mozilla.org/en/docs/Web/API/CanvasRenderingContext2D/lineCap
                  lineCap: "square",
                  // ボックス枠用。次をご覧ください。https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
                  lineDash: [0],
                  // ボックス枠用。次をご覧ください。https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
                  lineDashOffset: 0,
                  // ボックス枠用。次をご覧ください。https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineJoin
                  lineJoin: "bevel",
                  // 枠線の幅
                  lineWidth: 1,
                  // 凡例ボックスのストロークスタイル
                  strokeStyle: "",
                  // 凡例ボックスのポイントスタイル（usePointStyleがtrueの場合にのみ使用されます）
                  pointStyle: ""
                };
                legendArray.push(eachLengend);
              } // end for
              return legendArray;
            }
          },
        },
        plugins: {
          datalabels: {
            color: '#000',
            font: {
              weight: 'bold',
              // size: 20,
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage = ((value * 100) / sum).toFixed(0) + "%";
              // return percentage;
              return '';
            },
          }
        },
      },
    };
  },
  created() {
    // console.log("dataSet", this.dataSet)
    // console.log("options", this.options)
  },
});
</script>
