<template>
  <div class="contentsArea">
    <v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="primary" @click="onNewEnqueteInfo">
          <v-icon left>mdi-email-plus-outline</v-icon>
          新規アンケート実施
        </v-btn>
      </v-card-actions>
      <v-btn text @click="showSearchBox = !showSearchBox">
        <v-icon left large color="primary">
          {{ showSearchBox ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
        アンケート検索
      </v-btn>
      <v-expand-transition>
        <v-container v-if="showSearchBox" fluid>
          <v-form @submit.prevent ref="form" v-model="validSearchForm">
            <v-row>
              <v-col cols="2">
                <v-subheader>アンケート名</v-subheader>
              </v-col>
              <v-col>
                <v-autocomplete :items="items" item-text="deliveryInfo.deliveryName" item-value="deliveryInfo.deliveryName"
                  v-model="enqueteNameFilterValue" flat outlined dense clearable hide-details>
                  <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-subheader>アンケート実施者</v-subheader>
              </v-col>
              <v-col>
                <v-autocomplete :items="items" item-text="createdBy" item-value="staff_id"
                  v-model="enqueteOpneUserFilterValue" flat outlined dense clearable hide-details>
                  <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-subheader>
                  アンケート実施期間
                </v-subheader>
              </v-col>
              <v-col cols="auto">
                <v-menu v-model="fromDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fromDateText" readonly outlined dense prepend-inner-icon="mdi-calendar-outline"
                      placeholder="YYYY-MM-DD" clearable hide-details v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="selectedFromDate" no-title color="primary" locale="jp-ja"
                    :day-format="(date) => new Date(date).getDate()">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="auto"><v-subheader>~</v-subheader></v-col>
              <v-col cols="auto">
                <v-menu v-model="toDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="toDateText" readonly outlined dense prepend-inner-icon="mdi-calendar-outline"
                      placeholder="YYYY-MM-DD" clearable hide-details v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="selectedToDate" no-title color="primary" locale="jp-ja"
                    :day-format="(date) => new Date(date).getDate()">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-expand-transition>
      <v-divider></v-divider>
      <v-container class="my-4" fluid>
        <v-row no-gutters>
          <v-col cols="auto">
            <span class="display-1">{{ filteredItems.length }}</span>
            <span>件</span>
          </v-col>
          <v-divider vertical class="mx-4"></v-divider>
          <v-btn :loading="loading" color="primary" @click="reload">
            <v-icon left>mdi-reload</v-icon>
            データ更新
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" @click="clearSearchItems" :disabled="!canClearSearch" color="gray">
            条件をクリアする
          </v-btn>
        </v-row>
      </v-container>
      <v-data-table 
        :loading="loading" 
        :loading-text="loadingText" 
        :no-data-text="noDataText" 
        class="table-cursor" 
        :headers="headers" 
        :items="filteredItems"
        single-select 
        :items-per-page="perOnPage" 
        :footer-props="{ 'items-per-page-options': [30, 50, 100, -1], 'items-per-page-text': '行/ページ:' }"
        :page.sync="currentPage"
        @click:row="onEditEnqueteInfo">
        <template v-slot:[`item.deliveryInfo.enqueteType`]="{ item }">
          <div>{{ item.deliveryInfo.enqueteType ==  0 ? "LINE" : "外部" }}</div>
        </template>
        <template v-slot:[`item.enqueteStatus`]="{ item }">
          {{ enqueteStatusText(item.enqueteStatus) }}
        </template>
        <template v-slot:[`item.deliveryInfo.startDate`]="{ item }">
          <div>
            {{ item.deliveryInfo.startDate + " ~ " + item.deliveryInfo.endDate }}
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.createdAt }}
        </template>
        <template v-slot:[`item.answerRateNum`]="{ item }">
          <div>
            {{ answerRateText(item.targetNum, item.answerNum) }}
          </div>
        </template>
        <template v-slot:[`item.deleteBtn`]="{ item }">
          <div>
            <v-col align="center" class="px-0">
              <v-btn
                color="red white--text"
                class="mr-2"
                :disabled="canPublicEnd(item)"
                @click.stop="onPublicEndItem(item)"
              >公開終了</v-btn>
            </v-col>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  mapActions,
  mapMutations,
  mapState,
} from "vuex";
import {
  SET_ENQUETE_OPNE_INFO,
} from "@/store/mutation-types";
import {
  FETCH_ENQUETE_OPNE_INFOS,
  CLOSE_TO_PUBLIC_ENQUETE_OPEN_INFO,
} from "@/store/action-types";
import {
  LOADING_TEXT,
  NO_DATA_TEXT,
} from "@/constants";
import moment from 'moment';
import { cloneDeep } from "lodash";
export default Vue.extend({
  name: "EnqueteDeliveryList",
  data() {
    return {
      noDataText: NO_DATA_TEXT,
      loading: false,
      loadingText: LOADING_TEXT,
      showSearchBox: true,
      fromDateMenu: false,
      toDateMenu: false,
      selectedFromDate: null,
      selectedToDate: null,
      enqueteNameFilterValue: '',
      enqueteOpneUserFilterValue: '',
      canClearSearch: true,
      items: [],
      currentPage: 1,
      perOnPage: 10,
    };
  },
  computed: {
    ...mapState({
      enqueteOpenInfos: (state) => state.enqueteOpenInfos,
      loginUser: (state) => state.loginUser,
      enqueteOpenInfo: state => state.enqueteOpenInfo,
      fiscalYear: (state) => state.fiscalYear,
    }),
    headers() {
      return [
        { text: "アンケート名", value: "deliveryInfo.deliveryName", width: "20%" },
        { text: "タイプ", value: "deliveryInfo.enqueteType", width: "10%" },
        { text: "状況", value: "enqueteStatus", width: "10%" },
        { text: "期間", value: "deliveryInfo.startDate", width: "20%" },
        { text: "アンケート実施日時", value: "createdAt", width: "10%" },
        { text: "アンケート実施者", value: "createdBy", width: "10%" },
        { text: "回答率", value: "answerRateNum", width: "10%"},
        { value: "deleteBtn", sortable: false }
      ];
    },
    filteredItems() {
      let filteredItems = this.items;
      // テンプレート名の絞り込み
      if (this.enqueteNameFilterValue) {
        filteredItems = filteredItems.filter(item =>
          item.deliveryInfo.deliveryName.includes(this.enqueteNameFilterValue)
        );
      }
      // アンケート実施者の絞り込み
      if (this.enqueteOpneUserFilterValue) {
        filteredItems = filteredItems.filter(item =>
          item.createdBy.includes(this.enqueteOpneUserFilterValue)
        );
      }
      // 開催期間の絞り込み
      filteredItems = this.filterByPriod(filteredItems);

      for (let i = 0; i < filteredItems.length; i++) {
        filteredItems[i].answerRateNum = this.answerRateNum(filteredItems[i].targetNum, filteredItems[i].answerNum);
      }
      
      return filteredItems;
    },
    fromDateText: {
      get() {
        return this.selectedFromDate;
      },
      set(value) {
        if (!value) {
          this.selectedFromDate = null;
        }
      },
    },
    toDateText: {
      get() {
        return this.selectedToDate;
      },
      set(value) {
        if (!value) {
          this.selectedToDate = null;
        }
      },
    },
    validSearchForm: {
      get() {
        return true;
      },
      set(value) { console.log('validSearchForm', value) },
    },
  },
  watch: {
    enqueteOpenInfos(val) {
      this.items = cloneDeep(val);
    },
    async fiscalYear() {
      await this.reload();
    },
  },
  methods: {
    clearSearchItems() {
      this.enqueteNameFilterValue = null;
      this.enqueteOpneUserFilterValue = null;
      this.selectedToDate = null;
      this.selectedFromDate = null;
    },
    enqueteStatusText(item) {
      switch (item) {
        case "0":
          return "実施待ち";
        case "1":
          return "実施中";
        case "2":
          return "公開終了";
        default:
          return "実施待ち";
      }
    },
    answerRateText(targetNumStr, answerNumStr) {
      const targetNum = parseInt(targetNumStr);
      const answerNum = parseInt(answerNumStr);

      let answerRate = 0
      let answerRateRounded = 0

      if(targetNum != 0){
        answerRate = (answerNum / targetNum) * 100;
        answerRateRounded = (Math.round(answerRate * 100)) / 100; // 小数点第3位で四捨五入
      }
      return `${answerNum}/${targetNum} ${answerRateRounded}%`;
    },
    answerRateNum(targetNumStr, answerNumStr) {
      const targetNum = parseInt(targetNumStr);
      const answerNum = parseInt(answerNumStr);

      const answerRate = (answerNum / targetNum) * 100;
      const answerRateRounded = (Math.round(answerRate * 100)) / 100; // 小数点第3位で四捨五入
      return answerRateRounded
    },
    canPublicEnd(item) {
      const isBeforePublic = (item.enqueteStatus === "0")
      const isAfterPublic = (item.enqueteStatus === "2")

      return (isBeforePublic || isAfterPublic);
    },
    onPublicEndItem(item) {
      this.$dialog.show({
        title: "アンケートの回答受付を終了します。よろしいですか？",
        text: "この操作は取り消せません。",
        type: "error",
        btnConfirmTitle: "アンケートを終了する",
        onConfirm: async () => {
          this.loading = true;
          await this.closeToPublicEnqueteOpenInfo(item);
          this.loading = false;
        },
      });
    },
    onEditEnqueteInfo(item, row) {
      this.$router.push({ 
        name: "EnqueteDeliveryCreation",
        params: { 
          enqueteOpenId: item.enqueteOpenId,
        }
      });
    },
    onNewEnqueteInfo() {
      // 編集時に取得したenqueteInfoが存在する場合は初期化する
      if (this.enqueteOpenInfo) {
        this.setEnqueteOpenInfo({});
      }
      this.$router.push({ name: 'EnqueteDeliveryCreation' });
    },
    filterByPriod(enqueteOpenInfos) {
      if (!this.selectedFromDate && !this.selectedToDate) {
        // なにも選択されていない場合はすべて返す
        return enqueteOpenInfos;
      } else if (this.selectedFromDate && this.selectedToDate) {
        // 開始日と終了日の範囲内にあるアンケートをフィルタリング
        const filtered = enqueteOpenInfos.filter((e) => {
          return (
            moment(e.deliveryInfo.startDate) >= moment(this.selectedFromDate) && // 開催開始日が範囲内
            moment(e.deliveryInfo.endDate) <= moment(this.selectedToDate) // 開催終了日が範囲内
          );
        });

        return filtered;
      } else {
        // 開始日または終了日が選択されている場合
        if (this.selectedFromDate && !this.selectedToDate) {
          // 開始日のみ選択されている場合
          const filtered = enqueteOpenInfos.filter((e) => {
            return (
              // 開催開始日以降の日付を返却する
              moment(e.deliveryInfo.startDate) >= moment(this.selectedFromDate) // 開催開始日以降
            );
          });

          return filtered;
        } else if (!this.selectedFromDate && this.selectedToDate) {
          // 終了日のみ選択されている場合
          const filtered = enqueteOpenInfos.filter((e) => {
            return (
              // 開催終了日以前の日付を返却する
              moment(e.deliveryInfo.endDate) <= moment(this.selectedToDate) // 開催終了日以前
            );
          });

          return filtered;
        }
      }      
    },
    ...mapActions({
      fetchEnqueteOpenInfos: FETCH_ENQUETE_OPNE_INFOS,
      closeToPublicEnqueteOpenInfo: CLOSE_TO_PUBLIC_ENQUETE_OPEN_INFO,
    }),
    ...mapMutations({
      setEnqueteOpenInfo: SET_ENQUETE_OPNE_INFO,
    }),
    async reload() {
      this.loading = true;
      const payload = {
        "schoolId": this.loginUser.school_id,
        "loginUser": this.loginUser,
        "fiscalYear": this.fiscalYear,
      };
      await this.fetchEnqueteOpenInfos(payload);
      this.loading = false;
    },
  },
  async created() {
    await this.reload();
  },
});
</script>