<template>
  <div class="contentsArea">
    <v-card-actions style="max-height:50px;">
      <div class="text-right ma-4 row">
        <v-btn color="primary" text @click="$router.push({ name: 'DeliveryList' })">
            <v-icon left>mdi-arrow-left-circle</v-icon>前へ戻る
        </v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </div>
    </v-card-actions>
      
    <v-divider></v-divider>

    <div class="px-4 py-4">
      <p style="font-size: x-large">{{ delivery.delivery_name }}</p>
      <p class="mb-1">配信日時：{{ formatToYYYYMMDDHHmmss(delivery.delivered_at) }}</p>
      <p>配信者：{{ delivery.last_name }} {{ delivery.first_name }}</p>

      <div class="message-preview">
        <v-menu v-model="preview" top offset-y nudge-top="5px" opacity="0" :close-on-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" min-width="320" v-bind="attrs" v-on="on" @click="preview = !preview">
              <v-icon left> {{ preview ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon>
              配信内容を表示する
            </v-btn>
          </template>
          <div>
            <MessagePreview
              :title="deliveryDetail.deliveryName"
              :messages="deliveryDetail.messages"
              :positionInfo="emojisPositionInfo"
              :preview="preview"
              :url="this.deliveryDetail.messages.map(message => message.previewImageUrl)"
            />
          </div>
        </v-menu>
      </div>

      <v-data-table class="reply-message-table mt-8"
          style="width: 80%;"
          :loading="loading"
          :loading-text="loadingText"
          :no-data-text="noDataText"
          :headers="headers"
          :items="replyMessageList"
          single-select
          :items-per-page="10"
          :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1], 'items-per-page-text': '行/ページ:' }"
          :page.sync="currentPage"
        >
          <template v-slot:[`item.student_info.student_name`]="{ item }">
            <div>{{ item.student_info.last_name + ' ' + item.student_info.first_name }}</div>
          </template>
          <template v-slot:[`item.reply_message`]="{ item }">
            <div>
              <v-btn class="blue white--text"
                @click="handleMessageConfirmBtnClick(item)">
                確認
              </v-btn>
              <v-overlay 
                style="z-index: 14;"
                :value="isOverlayVisible" 
                :opacity="0.5"
                @click="isOverlayVisible = false">
                <div class="reply-message-dialog" @click.stop>
                  <div class="mt-4" style="display: flex; justify-content: space-between; align-items: center;">
                    <p style="color: black;">{{ currentStudentInfo }}</p>
                    <p style="color: black;" class="ml-2">返信日時 : {{ currentReplyCreatedAt }}</p>
                  </div>
                  <textarea readonly class="reply-message-textarea" v-model="currentReplyMessage"></textarea>
                  <div class="mt-4" style="display: flex; justify-content: center; align-items: center;">
                    <div class="reply-message-close-btn" @click="isOverlayVisible = false">閉じる</div>
                  </div>
                </div>
              </v-overlay>
            </div>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
          </template>
          <template v-slot:no-data>
            データがありません
          </template>
      </v-data-table>
    </div>

    <!-- ローディング -->
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapMutations, mapActions } from "vuex";
import {
  FETCH_REPLY_MESSAGES,
} from "@/store/action-types";
import {
} from "@/store/mutation-types";
import {
  NO_DATA_TEXT,
  LOADING_TEXT,
  REPLY_BUTTON_NAME,
  MARK_AS_READ_BUTTON_NAME,
} from "@/constants";
import MessagePreview from "./deliveryMessages/MessagePreview.vue";

export default {
  name: "DeliveryCreation",
  props: {
    delivery: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentStudentInfo: "",
      currentReplyCreatedAt: "",
      currentReplyMessage: "",
      isOverlayVisible: false,
      loadingText: LOADING_TEXT,
      currentPage: 1,
      preview: false,
      replyMessageList: [],
      noDataText : NO_DATA_TEXT,
      loading: false,
      inputImageValue: [],
      positionInfo: 1,
      deliveryDetail: {
        deliveryName: "",
        messages: [],
      },
      /* 絵文字関連 */
      emojisPositionInfo: [
        {
          emojis: [],
          text: "",
        }
      ],
    };
  },
  components: {
    MessagePreview,
  },
  computed: {
    ...mapState({
    }),
    headers() {
      return [
        { text: "学年", value: "student_info.grade_name", width: "10%" },
        { text: "組", value: "student_info.class_name", width: "10%" },
        { text: "生徒名", value: "student_info.student_name", width: "30%" },
        { text: "返信内容", value: "reply_message", width: "10%" },
        { text: "返信日時", value: "created_at", width: "15%" },
      ];
    },
  },
  watch: {},
  methods: {
    handleMessageConfirmBtnClick(item) {
      const studentInfo = item.student_info;
      this.currentStudentInfo = 
        studentInfo.grade_name + studentInfo.class_name + " " + 
        studentInfo.last_name + studentInfo.first_name;
      this.currentReplyCreatedAt = item.created_at;
      this.currentReplyMessage = item.reply_message;
      
      this.isOverlayVisible = true;
    },
    formatToYYYYMMDDHHmmss(value) {
      return moment(value).subtract(9, 'hours').format('YYYY-MM-DD HH:mm:ss')
    },
    ...mapActions({
      fetchReplyMessages: FETCH_REPLY_MESSAGES,
    }),
    ...mapMutations({}),
    setData() {
      // propsの値をdetailに入れる
      this.deliveryDetail.deliveryName = this.delivery.delivery_name;
      this.deliveryDetail.messages = this.delivery.delivery_contents;
      
      for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
        this.deliveryDetail.messages[i]["id"] = i;

        if (this.delivery?.delivery_contents?.[i]?.contents?.body?.contents?.[0]?.url != null)  {
          // pdfMsgの場合、detailとurl管理用の配列にURLを持たせなおす(DBに保存されたjsonオブジェクトを参照)
          this.inputImageValue[i] = [{ name: 'pdf' }]
          this.deliveryDetail.messages[i]["url"] = this.delivery.delivery_contents[i].contents.body.contents[0].url;
          this.deliveryDetail.messages[i]["pdfUrl"] = this.delivery.delivery_contents[i].contents.body.contents[0].action.uri;
          const aspectRatio = this.delivery.delivery_contents[i].contents.body.contents[0].aspectRatio;
          const splitAspectRatio = aspectRatio.split(':');
          this.deliveryDetail.messages[i]["imgWidth"] = splitAspectRatio[0];
          this.deliveryDetail.messages[i]["imgHeight"] = splitAspectRatio[1];
          this.deliveryDetail.messages[i].type = 'image';
          this.deliveryDetail.messages[i].previewImageUrl = this.delivery.delivery_contents[i].contents.body.contents[0].url
        } else if (this.deliveryDetail.messages[i]["type"] == "image") {
          // imageMsgの場合、detailとurl管理用の配列にURLを持たせなおす
          this.inputImageValue[i] = [{ name: 'image' }]
          this.deliveryDetail.messages[i]["url"] = this.delivery.delivery_contents[i]["originalContentUrl"]
        } else if (this.deliveryDetail.messages[i]["type"] == "flex") {
          // FlexMsgの場合、flexMessageキーを追加してcontentsを入れる
          this.deliveryDetail.messages[i].flexMessage = this.deliveryDetail.messages[i].contents;
        }

        /* 絵文字情報をセット */
        if (this.deliveryDetail.messages[i]["type"] == "text") {
          if (this.deliveryDetail.messages[i]["emojis"]) {
            const emojisPositionInfo = {
              emojis: this.deliveryDetail.messages[i]['emojis'],
            }
            this.emojisPositionInfo[i] = emojisPositionInfo;
          } else {
            const emojisPositionInfo = {
              emojis: [],
            }
            this.emojisPositionInfo[i] = emojisPositionInfo;
          }
        }
      }
    },
  },
  async created() {
    this.loading = true;

    /* 配信に紐づく返信メッセージ一覧を取得する */
    this.replyMessageList = await this.fetchReplyMessages(this.$route.query.deliveryId);

    /* データセット */
    this.setData();

    /* 返信用ボタン、既読更新ボタンはプレビュー表示しない */
    this.deliveryDetail.messages = this.deliveryDetail.messages.filter(msg => {
      return msg.altText !== REPLY_BUTTON_NAME && 
             msg.altText !== MARK_AS_READ_BUTTON_NAME;
    });

    this.loading = false;
  },
  async mounted() {}
};
</script>
<style scoped>
.message-preview {
  position: relative;
  width: 30%;
  z-index: 11;
}
.reply-message-table :deep(.v-data-table-header) {
  background-color: #CDE3FB;
}
.reply-message-dialog {
  background-color: white; 
  width: 700px; 
  height: 550px; 
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
}
.reply-message-textarea {
  width: 100%;
  height: 75%;
  border-style: solid;
  border-color: #D6D6D6;
  border-width: 1px;
  padding: 20px;
}
.reply-message-close-btn {
  box-shadow: 0 6px 20px rgb(93 93 93 / 23%);
  border: none;
  border-radius: 2px;
  height: 45px;
  width: 140px;
  background-color: #BBBBBB;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  padding-top: 10px;
}
</style>
