<template>
  <div class="date-picker">
    <v-menu
      class="menu"
      top
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          class="input-button px-2"
          v-bind="attrs"
          v-on="on"
        >
          <div class="flex">
            <div v-if="((value) && (value !== ''))">{{ value }}</div>
            <div v-else>日付を選択する</div>
            <v-icon>mdi-calendar-month</v-icon>
          </div>
        </button>
      </template>

      <div class="content">
        <v-row justify="space-between" class="mt-0 mx-0 mb-2">
          <v-col cols="auto" class="pa-0">
            <v-btn
              fab
              height="24"
              width="24"
              elevation="0"
              color="transparent"
              class="mr-2"
              @click="prevMonth($event)"
            >
              <v-icon color="green">mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col align="center" class="pa-0">
            <div>{{ currentYearDisplay + currentMonthDisplay }}</div>
          </v-col>
          <v-col cols="auto" class="pa-0">
            <v-btn
              fab
              height="24"
              width="24"
              elevation="0"
              color="transparent"
              class="mr-2"
              @click="nextMonth($event)"
            >
              <v-icon color="green">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div class="calendar">
          <div class="calendar-weekly">
            <div class="calendar-youbi" v-for="n in 7" :key="n">
              <div :class="(n === 1) ? 'sunDayCol' : (n === 7) ? 'satDayCol' : ''">
                {{ youbi(n-1) }}
              </div>
            </div>
          </div>
          <div class="calendar-weekly" v-for="(week, index) in calendars" :key="index">
            <div
              class="calendar-daily"
              :class="(currentMonth !== day.month) ? 'outside' : (isPastDay(day)) ? 'is-past' : ''"
              v-for="(day, index) in week" :key="index"
            >
              <div
                class="calendar-day"
                :class="(isSelectedDay(day)) ? 'is-selected' : ''"
                @click="clickDay(day)"
              >
                {{ day.day }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'DatePicker',
  props: [
    'value'
  ],
  data() {
    return {
      fromDateMenu: false,
      currentDate: moment(),
    };
  },
  computed: {
    calendars() {
      return this.getCalendar();
    },
    // displayDate() {
    //   return this.currentDate.format('YYYY[年]M[月]');
    // },
    currentYearDisplay() {
      return this.currentDate.format('YYYY年');
    },
    currentMonthDisplay() {
      return this.currentDate.format('M月');
    },
    currentMonth() {
      return this.currentDate.format('YYYY-MM');
    },
  },
  methods: {
    getStartDate() {
      let date = moment(this.currentDate);
      date.startOf("month");
      const youbiNum = date.day();
      return date.subtract(youbiNum, "days");
    },
    getEndDate() {
      let date = moment(this.currentDate);
      date.endOf("month");
      const youbiNum = date.day();
      return date.add(6 - youbiNum, "days");
    },
    getCalendar() {
      let startDate = this.getStartDate();
      const endDate = this.getEndDate();
      const weekNumber = Math.ceil(endDate.diff(startDate, "days") / 7);

      let calendars = [];
      let calendarDate = this.getStartDate();

      for (let week = 0; week < weekNumber; week++) {
        let weekRow = [];
        for (let day = 0; day < 7; day++) {
          weekRow.push({
            day: calendarDate.get('date'),
            month: calendarDate.format('YYYY-MM'), //追加
          });
          calendarDate.add(1, 'days');
        }
        calendars.push(weekRow);
      }
      return calendars;
    },
    nextMonth(event) {
      event.stopPropagation(); // イベントの伝播を防いで、v-menuが閉じないようにする
      this.currentDate = moment(this.currentDate).add(1, "month");
    },
    prevMonth(event) {
      event.stopPropagation(); // イベントの伝播を防いで、v-menuが閉じないようにする
      this.currentDate = moment(this.currentDate).subtract(1, "month");
    },
    youbi(dayIndex) {
      const week = ["日", "月", "火", "水", "木", "金", "土"];
      return week[dayIndex];
    },
    toDatetime(day) {
      return day.month + '-' + (day.day.toString().padStart(2, '0'));
    },
    clickDay(day) {
      const datetime = this.toDatetime(day);
      this.$emit('input', datetime);
    },
    isPastDay(day) {
      const datetime = this.toDatetime(day);
      const isBefore = moment(datetime).isBefore(moment(), "day");

      return isBefore;
    },
    isSelectedDay(day) {
      // 日付を指定していない場合は、当日を指定する
      if (this.value.length != 0) {
        return (this.value === this.toDatetime(day));
      } else {
        return (moment().format('YYYY-MM-DD') === this.toDatetime(day));
      }
    },
  },
  watch: {},
}
</script>

<style>
.flex {
  display: flex;
}

/* インプット欄 */
.input-button {
  border: solid 1px rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  background-color: rgba(240, 240, 240, 1);
  font-size: 0.9rem;
  line-height: 2;
}

/* カレンダー */
.content {
  margin: 0 auto;
  padding: 8px;
  width: 450px;
  background-color: rgba(255, 255, 255, 1);
}
.calendar{
  border-top: 1px solid #E0E0E0;
  font-size: 0.8em;
}
.calendar-weekly{
  display: flex;
  border-left: 1px solid #E0E0E0;
}
.calendar-daily{
  flex: 1;
  height: 30px;
  min-height: 100%;
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  margin-right: -1px;
}
.calendar-day{
  height: 100%;
  text-align: center;
}
.calendar-youbi {
  flex: 1;
  height: 30px;
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  margin-right: -1px;
  text-align: center;
  font-weight:bold;
  background-color: rgba(19, 153, 61, 0.1);
}
.outside {
  background-color: rgba(202, 202, 202, 1);
  border-color: rgba(255, 255, 255, 1);
}
.is-past {
  background-color: rgba(202, 202, 202, 1);
  border-color: rgba(255, 255, 255, 1);
}
.is-selected {
  background-color: rgba(19, 153, 61, 0.3);
}
.sunDayCol {
  color: #EF5350;
}
.satDayCol {
  color: #42A5F5;
}
</style>