import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from 'moment';
import formEditor from "./modules/formEditor";
import forms from "./modules/forms";
import { base64ToBlob } from '@/mixins/index.js'

import {
  SAVE_USER_NAME,
  SAVE_USER_PASSWORD,
  // SAVE_USER_LOGIN_TOKEN,
  SAVE_DELIVERY_ITEM,
  LOGIN_TRUE,
  LOGIN_FALSE,
  GET_STUDENTS_ALL_LENGTH,
  GET_STUDENTS_ALL,
  CREATE_STUDENT,
  INVALID_STUDENT,
  INVALID_MULTI_STUDENTS,
  LOGGED_TRUE,
  LOGGED_FALSE,
  GET_STAFF_DELIVERY_TARGET,
  UPDATE_STAFF_DELIVERY,
  GET_STAFF_GROUP_ALL,
  DELETE_STAFF_GROUP,
  CREATE_STAFF_GROUP,
  GET_STAFF_LIST_BY_STAFF_GROUP_ID,
  ADD_STAFF_LIST_STAFF_GROUP,
  DELETE_RELATION_STAFF_GROUP,
  INVALID_STAFF_GROUP,
  UPDATE_STAFF_GROUP,
  GET_STAFF_ALL,
  GET_STAFF_ALL_LENGTH,
  CREATE_STAFF,
  UPLOAD_MULTI_STAFF,
  INVALID_STAFF,
  INVALID_MULTI_STAFF,
  UPDATE_STUDENT,
  LOGIN_CHECK,
  CHANGE_PASSWORD,
  GET_CLASSROOM_ALL,
  GET_GRADE_ALL,
  GET_STUDENT_BY_CLASSROOM_ID,
  CONVERT_PDF_TO_JPEG,
  SAVE_DELIVERY_IMG_TO_S3,
  SEND_STAFF_DELIVERY,
  SEND_DELIVERY,
  SEND_TEST_DELIVERY,
  SEND_STUDENT_CSV,
  UPDATE_DELIVERY,
  DELETE_DELIVERY,
  UPDATE_STAFF,
  DELETE_STAFF_UID,
  DELETE_STUDENTS,
  DELETE_STAFF,
  GET_DELIVERY_ALL,
  GET_DELIVERY_ALL_LENGTH,
  FETCH_REPLY_MESSAGES,
  GET_GRADE_CLASS_ALL,
  GET_CURRENT_SCHOOL,
  CREATE_SCHOOL_ITEMS,
  UPDATE_SCHOOL_ITEMS,
  RESET_SCHOOL_ITEMS,
  FORCE_RESET_SCHOOL_ITEMS,
  CREATE_STUDENT_PDF,
  // GET_STUDENT_KEY,
  // UPDATE_STUDENT_SECURITY_KEY,
  // CREATE_STUDENT_SECURITY_KEY,
  // STUDENT_PDF_ISSUE,
  UPLOAD_MULTI_STUDENT,
  GET_PARENTS_NAME,
  DELETE_STUDENTS_PARENT,
  GET_SCHEDULE_ITEMS,
  DELETE_SCHEDULE_ITEMS,
  CREATE_SCHEDULE_ITEMS,
  UPDATE_SCHEDULE_ITEMS,

  GET_STUDENT_GROUP_ALL,
  DELETE_STUDENT_GROUP,
  CREATE_STUDENT_GROUP,
  ADD_STUDENT_LIST_STUDENT_GROUP,
  DELETE_RELATION_STUDENT_GROUP,
  INVALID_STUDENT_GROUP,
  UPDATE_STUDENT_GROUP,

  // 送迎バス機能
  GET_BUS_ALL,
  GET_BUS_ALL_LENGTH,
  CREATE_BUS,
  UPDATE_BUS,
  INVALID_BUS,
  DELETE_BUS,
  GET_BUS_ROUTE_LIST,
  GET_BUS_ROUTE_LENGTH,
  CREATE_BUS_ROUTE,
  UPDATE_BUS_ROUTE,
  INVALIDATE_BUS_ROUTE,
  NOTIFICATION_TEAMS,
  SAVE_BUS_ICON_TO_S3,
  START_SEND_BUS_LOCATION,
  SEND_BUS_LOCATION,
  END_SEND_BUS_LOCATION,
  CREATE_BUS_LOG,
  GET_BUS_LOG_YEAR,
  GET_BUS_IS_DRIVING,
  // アンケート機能
  REGISTER_ENQUETE_OPEN_INFO,
  CLOSE_TO_PUBLIC_ENQUETE_OPEN_INFO,
  FETCH_ENQUETE_OPNE_INFOS,
  GET_ENQUETE_OPNE_INFO,
  GET_ANALYSIS_ENQUETE_ANSWER,
  // 欠席連絡機能
  SEND_ABSENCE_FORM,
  GET_ABSENCE_FORM_ALL,
  GET_ABSENCE_FORM_ALL_LENGTH,
  UPDATE_INITIALIZE_PASSWORD_STAFF,
  SAVE_DELIVERY_PDF_TO_S3,
  // 学校給食
  FETCH_SCHOOL_LUNCH_FOOD_LIST,
  FETCH_SCHOOL_LUNCH_MENU_INFO_LIST,
  FETCH_SCHOOL_LUNCH_ALLERGEN_INFO_LIST,
  FETCH_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST,
  UPDATE_SCHOOL_LUNCH_ALLERGEN_SETTING
} from "./action-types";
import {
  SET_IS_SMART_PHONE,
  SET_USER_NAME,
  SET_USER_PASSWORD,
  SET_DELIVERY_ITEM,
  // SET_USER_LOGIN_TOKEN,
  CHANGE_LOGIN_STATUS,
  SET_STUDENTS_ALL,
  CHANGE_LOGGED_STATUS,
  SET_NEW_PASSWORD,
  SET_STAFF_ALL,
  SET_CREATED_STAFF,
  SET_CLASSROOM_ALL,
  SET_GRADE_ALL,
  SET_AUTH,
  SET_LOGIN_USER,
  SET_SELECTED_CLASSROOM_STUDENT,
  SET_SELECTED_CLASSROOM,
  SET_DELIVERY_IMGS,
  CLEAR_DELIVERY_IMGS,
  SET_DELIVERY_IMGS_NAME,
  SET_CREATED_STUDENT,
  SET_DELIVERY_DETAIL,
  SET_SCHOOL_ITEMS,
  SET_DELETE_TARGETS,
  SET_STUDENT_CSV,
  SET_DELIVERY_HISTORY_ITEMS,
  SET_GRADE_CLASS_ALL,
  SET_CURRENT_SCHOOL,
  SET_NEED_CREATE_SCHOOL,
  SET_NEED_CREATE_STAFF,
  SET_NEED_CREATE_STUDENT,
  SET_SEARCH_YEAR,
  SET_SCHEDULE_ITEMS,
  // 送迎バス機能
  SET_BUS_ALL,
  SET_CREATED_BUS,
  SET_BUS_ROUTE_LIST,
  SET_BUS_ROUTE_LENGTH,
  SET_BUS_LOG_HISTORY,
  SET_SELECTED_BUS,
  SET_SELECTED_BUS_ROUTE,
  SET_IS_DRIVING,
  SET_RESPONSE_NOTIFICATION_TEAMS_BUS_LOCATION,
  SET_RESPONSE_SEND_BUS_LOCATION,
  SET_EMOJI_OVER_20_ERROR,
  // エディタ機能
  SET_INPUT_URL,
  // アンケート機能
  SET_ENQUETE_DELIVERY_DETAIL,
  SET_ENQUETE_SETTINGS,
  SET_ENQUETE_OPNE_INFOS,
  SET_ENQUETE_OPNE_INFO,
  SET_IS_UN_PUBLISHED,
  SET_ANALYSIS_ENQUETE_ANSWER,
  SET_EXTERNAL_ENQUETE_URL,
  // 欠席連絡機能
  SET_ABSENCE_FORM_TO_SEND,
  SET_ABSENCE_FORM_ALL,
  SET_ABSENCE_FORM_ALL_LENGTH,

  SET_DELIVERY_PDFS,
  CLEAR_DELIVERY_PDFS,
  SET_DELIVERY_PDFS_NAME,
  // 学校給食
  SET_SCHOOL_LUNCH_FOOD_LIST,
  SET_SCHOOL_LUNCH_MENU_INFO_LIST,
  SET_SCHOOL_LUNCH_ALLERGEN_INFO_LIST,
  SET_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST
} from "./mutation-types";

Vue.use(Vuex);
const apiEndPoint = process.env.VUE_APP_BACKEND_ENDPOINT;

export default new Vuex.Store({
  state: {
    isSmartPhone: false,
    userName: null,
    userPassword: null,
    // userLoginToken: false,
    //ログイン済判定
    isLogin: false,
    //初回ログイン判定
    isLogged: false,
    newPassword: null,
    parentsAll: [],
    createdParent: null,
    createdStudent: null,
    staffAll: [],
    createdStaff: null,
    deliveryItem: {},
    deliveryHistoryItems: [],
    auth: {
      login_id: "",
      password: "",
    },
    loginUser: null,
    scheduleItems: [],
    classroomAll: [],
    gradeAll: [],
    gradeClassAll: null,
    currentSchool: {},
    selectedClassStudent: [],
    selectedClassroom: {
      grade_id: "",
      class_id: ""
    },
    deliveryImgs: [],
    deliveryImgsName: [],
    studentsAll: [],
    deleteTargets: [],
    deliveryDetail: {},
    schoolItems: [],
    studentCSV: null,
    setup: {
      needCreateSchool: false,
      needCreateStaff: false,
      needCreateStudent: false,
    },
    fiscalYear: moment().subtract(3, 'months').year(),
    busAll: [],
    createdBus: null,
    busRouteList: [],
    busRouteLength: 0,
    selectedBusRoute: {},
    busLogHistory: [],
    selectedBus: {},
    isDriving: false,
    responseNotificationTeamsBusLocation: null,
    responseSendBusLocation: null,
    emojiOver20Error: false,
    // エディタ機能
    inputUrl: "",
    // アンケート機能
    enqueteDeliveryDetail: {},
    enqueteSettings: {},
    enqueteOpenInfos: [],
    enqueteOpenInfo: {},
    isUnPublished: false,
    analysisEnqueteAnswer: {},
    externalEnqueteURL: "",
    // 欠席連絡機能
    absenceFormToSend: {},
    absenceFormAll: [],
    absenceFormAllLength: 0,

    deliveryPdfs: [],
    deliveryPdfsName: [],
    // 学校給食
    schoolLunchFoodList: [],
    schoolLunchMenuInfoList: [],
    schoolLunchAllergenInfoList: [],
    schoolLunchAllergenSettingStatusList: []
  },
  getters: {},
  mutations: {
    [SET_IS_SMART_PHONE](state, isSmartPhone) {
      state.isSmartPhone = isSmartPhone;
    },
    [SET_SELECTED_CLASSROOM](state, selectedClassroom) {
      state.selectedClassroom = selectedClassroom;
    },
    [SET_USER_NAME](state, userName) {
      state.userName = userName;
    },
    [SET_USER_PASSWORD](state, userPassword) {
      state.userPassword = userPassword;
    },
    [SET_DELIVERY_ITEM](state, deliveryItem) {
      state.deliveryItem = deliveryItem;
    },
    // [SET_USER_LOGIN_TOKEN](state, userLoginToken){
    //   state.userLoginToken = userLoginToken;
    // },
    [CHANGE_LOGIN_STATUS](state, isLogin) {
      state.isLogin = isLogin;
    },
    [CHANGE_LOGGED_STATUS](state, isLogged) {
      state.isLogged = isLogged;
    },
    [SET_STUDENTS_ALL](state, studentsAll) {
      state.studentsAll = studentsAll;
    },
    [SET_CREATED_STUDENT](state, createdStudent) {
      state.createdStudent = createdStudent;
    },
    [SET_STAFF_ALL](state, staffAll) {
      state.staffAll = staffAll;
    },
    [SET_CREATED_STAFF](state, createdStaff) {
      state.createdStaff = createdStaff;
    },
    [SET_CLASSROOM_ALL](state, classroomAll) {
      state.classroomAll = classroomAll;
    },
    [SET_GRADE_ALL](state, gradeAll) {
      state.gradeAll = gradeAll;
    },
    [SET_GRADE_CLASS_ALL](state, gradeClassAll) {
      state.gradeClassAll = gradeClassAll;
    },
    [SET_CURRENT_SCHOOL](state, currentSchool) {
      state.currentSchool = currentSchool;
    },
    [SET_AUTH](state, auth) {
      state.auth = auth;
    },
    [SET_NEW_PASSWORD](state, newPassword) {
      state.newPassword = newPassword;
    },
    [SET_LOGIN_USER](state, loginUser) {
      state.loginUser = loginUser;
    },
    [SET_SELECTED_CLASSROOM_STUDENT](state, selectedClassStudent) {
      state.selectedClassStudent = selectedClassStudent;
    },
    [SET_DELIVERY_IMGS](state, deliveryImgs) {
      state.deliveryImgs[deliveryImgs.index] = deliveryImgs.img;
    },
    [CLEAR_DELIVERY_IMGS](state) {
      state.deliveryImgs = [];
    },
    [SET_DELIVERY_IMGS_NAME](state, deliveryImgsName) {
      state.deliveryImgsName = deliveryImgsName;
    },
    [SET_DELIVERY_DETAIL](state, deliveryDetail) {
      state.deliveryDetail = deliveryDetail;
    },
    [SET_SCHOOL_ITEMS](state, schoolItems) {
      state.schoolItems = schoolItems;
    },
    [SET_DELETE_TARGETS](state, deleteTargets) {
      state.deleteTargets = deleteTargets;
    },
    [SET_STUDENT_CSV](state, studentCSV) {
      state.studentCSV = studentCSV;
    },
    [SET_DELIVERY_HISTORY_ITEMS](state, deliveryHistoryItems) {
      state.deliveryHistoryItems = deliveryHistoryItems;
    },
    [SET_NEED_CREATE_SCHOOL](state, bool) {
      state.setup.needCreateSchool = bool;
      localStorage.setItem('needCreateSchool', state.setup.needCreateSchool);
    },
    [SET_NEED_CREATE_STAFF](state, bool) {
      state.setup.needCreateStaff = bool;
      localStorage.setItem('needCreateStaff', state.setup.needCreateStaff);
    },
    [SET_NEED_CREATE_STUDENT](state, bool) {
      state.setup.needCreateStudent = bool;
      localStorage.setItem('needCreateStudent', state.setup.needCreateStudent);
    },
    [SET_SEARCH_YEAR](state, fiscalYear) {
      state.fiscalYear = fiscalYear;
    },
    [SET_SCHEDULE_ITEMS](state, scheduleItems) {
      state.scheduleItems = scheduleItems;
    },
    [SET_BUS_ALL](state, busAll) {
      state.busAll = busAll;
    },
    [SET_CREATED_BUS](state, createdBus) {
      state.createdBus = createdBus;
    },
    [SET_BUS_ROUTE_LIST](state, busRouteList) {
      state.busRouteList = busRouteList;
    },
    [SET_BUS_ROUTE_LENGTH](state, busRouteLength) {
      state.busRouteLength = busRouteLength;
    },
    [SET_SELECTED_BUS_ROUTE](state, selectedBusRoute) {
      state.selectedBusRoute = selectedBusRoute;
    },
    [SET_BUS_LOG_HISTORY](state, busLogHistory) {
      state.busLogHistory = busLogHistory;
    },
    [SET_SELECTED_BUS](state, selectedBus) {
      state.selectedBus = selectedBus;
    },
    [SET_IS_DRIVING](state, isDriving) {
      state.isDriving = isDriving;
    },
    [SET_RESPONSE_NOTIFICATION_TEAMS_BUS_LOCATION](state, response) {
      state.responseNotificationTeamsBusLocation = response;
    },
    [SET_RESPONSE_SEND_BUS_LOCATION](state, response) {
      state.responseSendBusLocation = response;
    },
    [SET_EMOJI_OVER_20_ERROR](state, flg) {
      state.emojiOver20Error = flg;
    },
    // エディタ機能
    [SET_INPUT_URL](state, inputUrl) {
      state.inputUrl = inputUrl;
    },
    // アンケート機能
    [SET_ENQUETE_DELIVERY_DETAIL](state, enqueteDeliveryDetail) {
      state.enqueteDeliveryDetail = enqueteDeliveryDetail;
    },
    [SET_ENQUETE_SETTINGS](state, enqueteSettings) {
      state.enqueteSettings = enqueteSettings;
    },
    [SET_ENQUETE_OPNE_INFOS](state, enqueteOpenInfos) {
      state.enqueteOpenInfos = enqueteOpenInfos;
      // updatedAtプロパティで降順に並び替え
      state.enqueteOpenInfos.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
    },
    [SET_ENQUETE_OPNE_INFO](state, enqueteOpenInfo) {
      state.enqueteOpenInfo = enqueteOpenInfo;
    },
    [SET_IS_UN_PUBLISHED](state, isUnPublished) {
      state.isUnPublished = isUnPublished;
    },
    [SET_ANALYSIS_ENQUETE_ANSWER](state, analysisEnqueteAnswer) {
      state.analysisEnqueteAnswer = analysisEnqueteAnswer;
    },
    [SET_EXTERNAL_ENQUETE_URL](state, externalEnqueteURL) {
      state.externalEnqueteURL = externalEnqueteURL;
    },
    // 欠席連絡機能
    [SET_ABSENCE_FORM_TO_SEND](state, absenceFormToSend) {
      state.absenceFormToSend = absenceFormToSend;
    },
    [SET_ABSENCE_FORM_ALL](state, absenceFormAll) {
      state.absenceFormAll = absenceFormAll;
    },
    [SET_ABSENCE_FORM_ALL_LENGTH](state, absenceFormAllLength) {
      state.absenceFormAllLength = absenceFormAllLength;
    },
    [SET_DELIVERY_PDFS](state, deliveryPdfs) {
      state.deliveryPdfs[deliveryPdfs.index] = deliveryPdfs.img;
    },
    [CLEAR_DELIVERY_PDFS](state) {
      state.deliveryPdfs = [];
    },
    [SET_DELIVERY_PDFS_NAME](state, deliveryPdfsName) {
      state.deliveryPdfsName = deliveryPdfsName;
    },
    // 学校給食
    [SET_SCHOOL_LUNCH_FOOD_LIST](state, schoolLunchFoodList) {
      state.schoolLunchFoodList = schoolLunchFoodList;
    },
    [SET_SCHOOL_LUNCH_MENU_INFO_LIST](state, schoolLunchMenuInfoList) {
      state.schoolLunchMenuInfoList = schoolLunchMenuInfoList;
    },
    [SET_SCHOOL_LUNCH_ALLERGEN_INFO_LIST](state, schoolLunchAllergenInfoList) {
      state.schoolLunchAllergenInfoList = schoolLunchAllergenInfoList;
    },
    [SET_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST](state, schoolLunchAllergenSettingStatusList) {
      state.schoolLunchAllergenSettingStatusList = schoolLunchAllergenSettingStatusList;
    }
  },
  actions: {
    [GET_PARENTS_NAME]: async ({ state }, student_id) => {
      console.log(state)
      const apiUrl = apiEndPoint + "/parents/get-parents-name";
      return await axios
        .post(apiUrl, {
          loginUser : state.loginUser,
          fiscal_year : state.fiscalYear,
          student_id : student_id,
        })
        .then((response) => {
          console.log('response', response)
          return response.data
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // [UPDATE_STUDENT_SECURITY_KEY]: async ({ state }, security_key_id) => {
    //   console.log(state)
    //   const apiUrl = apiEndPoint + "/dev/student/update-student-key";
    //   await axios
    //     .post(apiUrl, {
    //       security_key_id: security_key_id,
    //     })
    //     .then((response) => {
    //       console.log('response', response)
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },
    // [CREATE_STUDENT_SECURITY_KEY]: async ({ state }, student_id) => {
    //   console.log(state)
    //   console.log('student_id', student_id)
    //   const apiUrl = apiEndPoint + "/dev/student/create-student-key";
    //   await axios
    //     .post(apiUrl, {
    //       student_id : student_id,
    //     })
    //     .then((response) => {
    //       console.log('response', response);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },
    // すべての生徒情報取得
    // [GET_STUDENT_KEY]: async ({ state }, student_id) => {
    //   console.log(state)
    //   const apiUrl = apiEndPoint + "/dev/student/get-student-key";
    //   return await axios
    //     .post(apiUrl, {
    //       student_id: student_id,
    //     })
    //     .then((response) => {
    //       if (response && response.data && response.data.body) {
    //         return response.data.body
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },
    // 全ての生徒の人数を取得
    [GET_STUDENTS_ALL_LENGTH]: async ({ state }) => {
      console.log(state)
      const apiUrl = apiEndPoint + "/dev/student/get-students-length";
      return await axios
        .post(apiUrl, {
          loginUser: state.loginUser,
          year: state.fiscalYear
        })
        .then((response) => {
          if (response && response.data) {
            return response.data
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // すべての生徒情報取得
    [GET_STUDENTS_ALL]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/dev/student/get-students";
      await axios
        .post(apiUrl, {
          loginUser: state.loginUser,
          year: state.fiscalYear
        })
        .then((response) => {
          if (response && response.data && response.data.body) {
            commit(SET_STUDENTS_ALL, response.data.body);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [UPLOAD_MULTI_STUDENT]: async ({ state }, student_list) => {
      const apiurl = apiEndPoint + "/student/upload-multi-student";
      const body = {
        student: student_list,
        year: state.fiscalYear,
        loginUser: {
          school_id: state.loginUser.school_id,
          staff_id: state.loginUser.staff_id
        }
      };
      const res = await axios
        .post(apiurl, body)
        .then((response) => {
          return response.data
        })
        .catch((err) => {
          console.error(err);
        });
      return res;
    },
    [SEND_STUDENT_CSV]: async ({ commit, state }) => {
      let formData = new FormData();
      const apiurl = apiEndPoint + "/student/upload-student-csv";
      formData.append('studentCSV', state.studentCSV);
      formData.append('year', state.fiscalYear);
      let config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const res = axios
        .post(apiurl, formData, config)
        .then(function (response) {
          console.log('response', response)
          console.log(commit)
          commit(SET_CREATED_STUDENT, response.data.body);
          return response.data
        })
        .catch(function (error) {
          // error 処理
          console.log('err', error);
        })
      return res;
    },
    // 生徒情報を無効化
    [INVALID_STUDENT]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/dev/student/invalid-student";
      const body = {
        student: state.createdStudent,
        year: state.fiscalYear,
        login_user: {
          staff_id: state.loginUser.staff_id
        }
      };
      const res = await axios
        .post(apiUrl, body)
        .then((response) => {
          return response.data
        })
        .catch((err) => {
          console.error(err);
        });
      return res;
    },
    // 生徒情報を一括無効化
    [INVALID_MULTI_STUDENTS]: async ({ state }, students_list) => {
      const apiUrl = apiEndPoint + "/dev/student/invalid-multi-students";
      const body = {
        invalidStudents: students_list,
        year: state.fiscalYear,
        login_user: {
          school_id: state.loginUser.school_id,
          staff_id: state.loginUser.staff_id,
        }
      };
      console.log('body', body)
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("INVALID_MULTI_STUDENTS", response.data)
          return response.data
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 生徒情報を新規追加
    [CREATE_STUDENT]: async ({ commit, state, dispatch }) => {
      const apiUrl = apiEndPoint + "/dev/student/create-student";
      const body = {
        student: state.createdStudent,
        year: state.fiscalYear,
        school_id: state.loginUser.school_id,
        staff_id: state.loginUser.staff_id,
      };

      const res = await axios
        .post(apiUrl, body)
        .then((response) => {
          commit(SET_CREATED_STUDENT, response.data.body);
          return response.data
        })
        .catch((err) => {
          console.error(err);
        });
      await dispatch(`GET_STUDENTS_ALL`, { commit });
      return res;
    },
    // 生徒情報を更新
    [UPDATE_STUDENT]: async ({ commit, state, dispatch }) => {
      const apiUrl = apiEndPoint + "/dev/student/update-student";
      const body = {
        student: state.createdStudent,
        staff_id: state.loginUser.staff_id,
        year: state.fiscalYear,
      };

      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("UPDATE_STUDENT", response);
          console.log("UPDATE_STUDENT", commit);

        })
        .catch((err) => {
          console.error(err);
        });
      await dispatch(`GET_STUDENTS_ALL`, { commit });
    },
    // 生徒情報の 削除処理
    [DELETE_STUDENTS]: async ({ commit, state, dispatch }) => {
      const apiUrl = apiEndPoint + "/dev/student/delete-student";
      const body = { student_ids: state.deleteTargets };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("DELETE_STUDENTS", response);
          console.log("DELETE_STUDENTS", commit);
        })
        .catch((err) => {
          console.error(err);
        });
      await dispatch(`GET_STUDENTS_ALL`, { commit });
    },
    // すべての職員グループ情報取得
    [GET_STAFF_GROUP_ALL]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/staff/get-staffgroup-all";
      const body = {
        school_id: state.loginUser.school_id,
        year: state.fiscalYear
      };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response.data) {
            return response.data
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 職員グループを削除
    [DELETE_STAFF_GROUP]: async ({ state }, staffGroupId) => {
      const apiUrl = apiEndPoint + "/staff/delete-staffgroup-all";
      const body = { school_id: state.loginUser.school_id, staff_group_id: staffGroupId };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 職員グループを更新
    [UPDATE_STAFF_GROUP]: async ({ state }, staffGroup) => {
      const apiUrl = apiEndPoint + "/staff/update-staffgroup-all";
      const body = { 
        school_id: state.loginUser.school_id,
        staff_group: staffGroup,
        loginUser: state.loginUser
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 職員グループを作成
    [INVALID_STAFF_GROUP]: async ({ state }, staffGroup) => {
      const apiUrl = apiEndPoint + "/staff/invalid-staffgroup-all";
      const body = { 
        school_id: state.loginUser.school_id,
        staff_group: staffGroup,
        loginUser: state.loginUser
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 職員グループを作成
    [CREATE_STAFF_GROUP]: async ({ state }, staffGroup) => {
      const apiUrl = apiEndPoint + "/staff/create-staffgroup-all";
      const body = { 
        school_id: state.loginUser.school_id,
        staff_group: staffGroup,
        loginUser: state.loginUser,
        year: state.fiscalYear
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
      },
    // 職員グループを作成
    [GET_STAFF_LIST_BY_STAFF_GROUP_ID]: async ({ state }, staff_group_id) => {
      const apiUrl = apiEndPoint + "/staff/get_staff_by_group_id";
      const body = { 
        school_id: state.loginUser.school_id,
        staff_group_id: staff_group_id,
        loginUser: state.loginUser
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 職員グループを作成
    [ADD_STAFF_LIST_STAFF_GROUP]: async ({ state }, obj) => {
      const apiUrl = apiEndPoint + "/staff/add-staff-list-staff-group";
      const body = { 
        school_id: state.loginUser.school_id,
        staff_group_id: obj.staff_group_id,
        staff_id_list: obj.staff_id_list,
        loginUser: state.loginUser
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [DELETE_RELATION_STAFF_GROUP]: async ({ state }, {staff_group_id ,staff_id}) => {
      const apiUrl = apiEndPoint + "/staff/delete-relation-staff-group";
      const body = { 
        school_id: state.loginUser.school_id,
        staff_group_id: staff_group_id,
        staff_id: staff_id,
        loginUser: state.loginUser
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 全ての職員の人数取得
    [GET_STAFF_ALL_LENGTH]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/dev/staff/get-staff-all-length";
      const body = { 
        year: state.fiscalYear,
        loginUser: state.loginUser
      };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
          if (response && response.data) {
            return response.data
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // すべての職員情報取得
    [GET_STAFF_ALL]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/dev/staff/get-staff-all";
      const body = { 
        year: state.fiscalYear,
        loginUser: state.loginUser
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
          if (response && response.data && response.data.body) {
            commit(SET_STAFF_ALL, response.data.body);
          }

        })
        .catch((err) => {
          console.error(err);
        });
    },
    // すべてのクラスルーム情報取得
    [GET_CLASSROOM_ALL]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/dev/classroom/get-classroom-all";
      const body = {
        loginUser: {
          authority: state.loginUser.authority,
          managed_grade: state.loginUser.managed_grade,
          managed_class: state.loginUser.managed_class,
          school_id: state.loginUser.school_id,
        },
        year: state.fiscalYear
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
          if (response && response.data && response.data.body) {
            commit(SET_CLASSROOM_ALL, response.data.body);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // すべての学年情報取得
    [GET_GRADE_ALL]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/dev/classroom/get-grade-all";
      const body = {
        loginUser: {
          authority: state.loginUser.authority,
          school_id: state.loginUser.school_id,
        },
        year: state.fiscalYear
      }
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
          if (response && response.data && response.data.body) {
            commit(SET_GRADE_ALL, response.data.body);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 職員情報を無効化
    [INVALID_STAFF]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/dev/staff/invalid-staff";
      const body = {
        createdStaff: state.createdStaff,
        year: state.fiscalYear,
        login_user: state.loginUser,
      }
      console.log('body', body)
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          // commit(SET_CREATED_STAFF, response.data.body);
          return response.data
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 職員情報を一括無効化
    [INVALID_MULTI_STAFF]: async ({ state }, staff_list) => {
      const apiUrl = apiEndPoint + "/dev/staff/invalid-multi-staff";
      const body = {
        invalidStaff: staff_list,
        year: state.fiscalYear,
        login_user: state.loginUser,
      };
      console.log('body', body)
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("INVALID_MULTI_STAFF", response.data)
          return response.data
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 職員情報を新規追加
    [CREATE_STAFF]: async ({ commit, state }) => {
      console.log('CREATE_STAFF')
      console.log('commit', commit)
      console.log('stateCreateStaff', state.createdStaff)
      const apiUrl = apiEndPoint + "/dev/staff/create-staff";
      const body = {
        loginUser: state.loginUser,
        createdStaff: state.createdStaff,
        year: state.fiscalYear,
        school_id: state.loginUser.school_id
      }
      console.log('body', body)
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("CREATE_STAFF", response);
          console.log("CREATE_STAFF", commit);
          commit(SET_CREATED_STAFF, response.data.body);
          return response.data
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 幾つかの職員情報を追加
    [UPLOAD_MULTI_STAFF]: async ({ state }, staff_id_list) => {
      console.log('UPLOAD_MULTI_STAFF')
      const apiurl = apiEndPoint + "/dev/staff/upload-multi-staff";
      const body = {
        staff: staff_id_list,
        login_user: state.loginUser,
        year: state.fiscalYear,
        school_id: state.loginUser.school_id
      };
      console.log("body", body)
      const res = await axios
        .post(apiurl, body)
        .then((response) => {
          // console.log('UPDATE_MULTI_STAFF', response);
          return response.data
        })
        .catch((err) => {
          console.error(err);
        });
      return res;
    },
    //職員のLINE UID 連携解除
    [DELETE_STAFF_UID]: async ({ state }, staff) => {
      console.log(staff)
      const apiUrl = apiEndPoint + "/dev/staff/delete-staff-uid";
      const body = {
        staff: staff.staff_id,
        login_user: state.loginUser,
      }

      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("DELETE_STAFF_UID", response);
          return response
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 職員情報を更新
    [UPDATE_STAFF]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/dev/staff/update-staff";
      const body = {
        staff: state.createdStaff,
        login_user: state.loginUser,
      }

      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("UPDATE_STAFF", response);
          console.log("UPDATE_STAFF", commit);
          return response.data
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 職員情報の削除処理
    [DELETE_STAFF]: async ({ commit, state, dispatch }) => {
      const apiUrl = apiEndPoint + "/dev/staff/delete-staff";
      const body = { staff_ids: state.deleteTargets };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("DELETE_STAFF", response);
          console.log("DELETE_STAFF", commit);
        })
        .catch((err) => {
          console.error(err);
        });
      await dispatch(`GET_STAFF_ALL`, { commit });
    },
    // ログイン処理
    [LOGIN_CHECK]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/dev/staff/login";
      const body = state.auth;
      const result = await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response.data.statusCode) {
            if (response.data.statusCode == 200) {
              if (response.data.isLogin) {
                const staff = response.data.staff;
                commit(SET_LOGIN_USER, staff);

                // localStorageにログイン情報を保存
                const newExpirationTime = moment().utc().add(9, 'h').add(10, 'm')

                const loginInfo = JSON.stringify({
                  isLogin: true,
                  data: response.data,
                  expirationTime: newExpirationTime
                });
                localStorage.setItem('loginInfo', loginInfo);

                // 管理者なら初期設定が必要かチェック
                if (staff.authority === 1) {
                  if (response.data.needCreateSchool) {
                    commit(SET_NEED_CREATE_SCHOOL, true);
                  }
                  if (response.data.needCreateStaff) {
                    commit(SET_NEED_CREATE_STAFF, true);
                  }
                  if (response.data.needCreateStudent) {
                    commit(SET_NEED_CREATE_STUDENT, true);
                  }
                }
                localStorage.setItem('needCreateSchool', state.setup.needCreateSchool);
                localStorage.setItem('needCreateStaff', state.setup.needCreateStaff);
                localStorage.setItem('needCreateStudent', state.setup.needCreateStudent);
              }
            }
            return response.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return result;
    },
    // バス情報取得
    [GET_BUS_ALL]: async ({ state, commit }) => {
      const apiUrl = apiEndPoint + "/dev/bus/get-bus-all";
      const body = {
        school_id: state.loginUser.school_id
      }
      await axios.post(apiUrl, body)
        .then((response) => {
          console.log('GET_BUS_ALL response', response);
          if (response && response.data && response.data.body) {
            commit(SET_BUS_ALL, response.data.body);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バス情報数取得
    [GET_BUS_ALL_LENGTH]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/dev/bus/get-bus-all-length";
      const body = {
        school_id: state.loginUser.school_id
      }
      return await axios.post(apiUrl, body)
        .then((response) => {
          console.log('GET_BUS_ALL_LENGTH response', response);
          if (response && response.data) {
            return response.data
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バス情報作成
    [CREATE_BUS]: async ({ state, commit }) => {
      const apiUrl = apiEndPoint + "/dev/bus/create-bus";
      const body = {
        bus_name: state.createdBus.bus.bus_name,
        school_id: state.loginUser.school_id,
        staff_id: state.loginUser.staff_id
      }
      return await axios.post(apiUrl, body)
        .then((response) => {
          console.log('CREATE_BUS response', response);
          commit(SET_CREATED_BUS, response.data.body);
          return response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バス情報編集
    [UPDATE_BUS]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/dev/bus/update-bus";
      const body = {
        bus: state.createdBus,
        loginUser : state.loginUser
      }
      return await axios.post(apiUrl, body)
        .then((response) => {
          console.log('UPDATE_BUS response', response);
          return response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バス情報無効化
    [INVALID_BUS]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/dev/bus/invalid-bus";
      const body = {
        bus: state.createdBus,
        loginUser: state.loginUser
      }
      return await axios.post(apiUrl, body)
        .then((response) => {
          console.log('INVALID_BUS response', response);
          return response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バス情報削除
    [DELETE_BUS]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/dev/bus/delete-bus";
      const body = {
        bus_ids: state.deleteTargets
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("DELETE_BUS response", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バスルート情報取得
    [GET_BUS_ROUTE_LIST]: async ({ state, commit }) => {
      const apiUrl = apiEndPoint + "/bus-route/get-bus-route-list";
      const body = {
        school_id: state.loginUser.school_id
      };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('GET_BUS_ROUTE_LIST response', response);
          if (response && response.data && response.data.body) {
            commit(SET_BUS_ROUTE_LIST, response.data.body);
          }
          return response;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バスルート情報数取得
    [GET_BUS_ROUTE_LENGTH]: async ({ state, commit }) => {
      const apiUrl = apiEndPoint + "/bus-route/get-bus-route-length";
      const body = {
        school_id: state.loginUser.school_id
      };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('GET_BUS_ROUTE_LENGTH response', response);
          if (response && response.data && response.data.body) {
            commit(SET_BUS_ROUTE_LENGTH, response.data.body);
          }
          return response;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バスルート情報作成
    [CREATE_BUS_ROUTE]: async ({ state }, bus_route) => {
      const apiUrl = apiEndPoint + "/bus-route/create-bus-route";
      const body = {
        school_id: state.loginUser.school_id,
        created_by: (state.loginUser.last_name + state.loginUser.first_name),
        bus_route_name: bus_route.bus_route_name,
        route_info: bus_route.route_info
      };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('CREATE_BUS_ROUTE response', response);
          return response;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バスルート情報更新
    [UPDATE_BUS_ROUTE]: async ({ state }, bus_route) => {
      const apiUrl = apiEndPoint + "/bus-route/update-bus-route";
      const body = {
        updated_by: (state.loginUser.last_name + state.loginUser.first_name),
        bus_route_id: bus_route.bus_route_id,
        bus_route_name: bus_route.bus_route_name,
        route_info: bus_route.route_info
      };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('UPDATE_BUS_ROUTE response', response);
          return response;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バスルート情報無効化
    [INVALIDATE_BUS_ROUTE]: async ({ state }, bus_route) => {
      const apiUrl = apiEndPoint + "/bus-route/invalidate-bus-route";
      const body = {
        updated_by: (state.loginUser.last_name + state.loginUser.first_name),
        bus_route_id: bus_route.bus_route_id,
      };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('INVALIDATE_BUS_ROUTE response', response);
          return response;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バスのログ情報作成
    [CREATE_BUS_LOG]: async ({ state }, { bus_log_type, bus_log_content }) => {
      const apiUrl = apiEndPoint + "/dev/bus/create-bus-log";
      const body = {
        bus_id: state.selectedBus.bus_id,
        school_id: state.loginUser.school_id,
        bus_log_type: bus_log_type,
        bus_log_content: bus_log_content,
      }
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // バスのログ情報取得
    [GET_BUS_LOG_YEAR]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/dev/bus/get-bus-log-year";
      const body = {
        school_id: state.loginUser.school_id,
        fiscal_year: state.fiscalYear
      }
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('GET_BUS_LOG_YEAR response', response);
          if (response && response.data && response.data.body) {
            commit(SET_BUS_LOG_HISTORY, response.data.body);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_BUS_ICON_TO_S3]: async ({ state }, blob) => {
      const apiUrl = apiEndPoint + '/bus/get-presigned-url-bus-icon';
      const body = {
        school_id: state.loginUser.school_id,
        bus_id: state.selectedBus.bus_id
      };
      // PresignedURL取得処理
      let presignedUrl = await axios.post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_BUS_ICON_TO_S3 get-presigned response", response);
          return response.data.body;
        })
        .catch((err) => {
          console.error(err);
        });

      if (!presignedUrl) {
        return;
      }

      // s3に保存処理
      return await fetch(presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": ".png",
        },
        body: blob,
      })
        .then((response) => {
          console.log("SAVE_BUS_ICON_TO_S3 put response", response);
          const pattern = /https:\/\/[^?]+/;
          const s3Url = response.url.match(pattern)[0];
          return s3Url;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 走行開始
    [START_SEND_BUS_LOCATION]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/dev/bus/start-send-bus-location";
      const body = {
        bus_id: state.selectedBus.bus_id,
        school_id: state.loginUser.school_id,
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("START_SEND_BUS_LOCATION", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 位置情報送信
    [SEND_BUS_LOCATION]: async ({ state, commit }, { lat, lng, icon_url }) => {
      const apiUrl = apiEndPoint + "/dev/bus/send-bus-location";
      const body = {
        bus_id: state.selectedBus.bus_id,
        bus_route_id: state.selectedBusRoute.bus_route_id,
        school_id: state.loginUser.school_id,
        lat: lat,
        lng: lng,
        icon_url: icon_url
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SEND_BUS_LOCATION", response);
          commit('SET_RESPONSE_SEND_BUS_LOCATION', response);
        })
        .catch((err) => {
          console.error(err);
          commit('SET_RESPONSE_SEND_BUS_LOCATION', err);
        });
    },
    // 走行終了
    [END_SEND_BUS_LOCATION]: async({ state }) => {
      const apiUrl = apiEndPoint + "/dev/bus/end-send-bus-location";
      const body = {
        bus_id: state.selectedBus.bus_id,
        school_id: state.loginUser.school_id,
      };
      const blob = new Blob([JSON.stringify(body, null, 2)], {
        type: 'application/json',
      });
      await fetch(apiUrl, {
        method: 'POST',
        body: blob,
        headers: {
          "Access-Control-Allow-Credentials": true
        },
        keepalive: true,
      });
    },
    // バスの走行状態を取得
    [GET_BUS_IS_DRIVING]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/dev/bus/get-bus-is-driving-by-bus-id";
      const body = {
        school_id: state.loginUser.school_id,
        bus_id: state.selectedBus.bus_id,
      };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          return response.data.body;
        })
        .catch((err) => {
          if (err.code === "ERR_NETWORK") {
            return -1;
          } else {
            console.error(err);
          }
        });
    },
    [GET_STUDENT_BY_CLASSROOM_ID]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/students/get-students-by-classroom-id";
      const body = {
        class_id: state.selectedClassroom.class_id,
        grade_id: state.selectedClassroom.grade_id,
        school_id: state.loginUser.school_id,
        fiscal_year: state.fiscalYear
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
          commit(SET_SELECTED_CLASSROOM_STUDENT, response.data.student_list);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // [STUDENT_PDF_ISSUE]: async ({ commit, state }, student_id_list) => {
    //   return await axios
    //     .post(
    //       apiEndPoint + "/dev/student/student-pdf-issue",
    //       {
    //         student: student_id_list,
    //         school_id: state.loginUser.school_id
    //       }
    //     )
    //     .then(res => {
    //       console.log(commit)
    //       if(res.data.statusCode == 200){
    //         const link = document.createElement('a')
    //         link.href = res.data.body
    //         link.click()
    //       }
    //       return res.data
    //     })
    //     .catch(err => {
    //       console.log("Error:", err)
    //     });
    // },
    [DELETE_STUDENTS_PARENT]: async ({ commit, state }, relation) => {
      return await axios
        .post(
          apiEndPoint + "/student/delete_students_parent",
          {
            relation: relation,
            year: state.fiscalYear,
            school_id: state.loginUser.school_id,
            loginUser: state.loginUser,
          }
        )
        .then(res => {
          console.log(commit)
          if(res.data.statusCode == 200){
            console.log('res', res)
          }
          return res.data
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    [CREATE_STUDENT_PDF]: async ({ commit, state }, student_id_list) => {
      return await axios
        .post(
          apiEndPoint + "/dev/student/create_student_pdf",
          {
            student: student_id_list,
            school_id: state.loginUser.school_id,
            loginUser: state.loginUser,
            year: state.fiscalYear,
          }
        )
        .then(res => {
          console.log(commit)
          return res.data
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    // 職員配信の配信先情報について取得する
    [GET_STAFF_DELIVERY_TARGET]: async ({state}, delivery_id) => {
      const apiUrl = apiEndPoint + "/get-staff-delivery-target";
      const body = { school_id: state.loginUser.school_id, delivery_id : delivery_id };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response.data) {
            return response.data
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    //職員向け配信
    [SEND_STAFF_DELIVERY]: async ({ commit, state }) => {
      return await axios
        .post(
          apiEndPoint + "/staff-delivery",
          {
            deliveryDetail: state.deliveryDetail,
            loginUser: state.loginUser,
            fiscal_year: state.fiscalYear
          }
        )
        .then(res => {
          console.log(commit)
          console.log('res', res);
          return res.data
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    [SEND_TEST_DELIVERY]: async ({ commit, state }, staff_group_id) => {
      console.log('staff_group_id', staff_group_id)
      return await axios
        .post(
          apiEndPoint + "/test-delivery",
          {
            deliveryDetail: state.deliveryDetail,
            loginUser: state.loginUser,
            fiscal_year: state.fiscalYear,
            staff_group_id : staff_group_id
          }
        )
        .then(res => {
          console.log('配信完了しました')
          console.log(commit)
          console.log('res', res);
          return res.data
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    [SEND_DELIVERY]: async ({ commit, state }) => {
      console.log('SEND_DELIVERYです', SEND_DELIVERY)
      return await axios
        .post(
          apiEndPoint + "/delivery",
          {
            deliveryDetail: state.deliveryDetail,
            loginUser: state.loginUser,
            fiscal_year: state.fiscalYear
          }
        )
        .then(res => {
          console.log('配信完了しました')
          console.log(commit)
          console.log('res', res);
          return res.data
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    [FORCE_RESET_SCHOOL_ITEMS]: async ({ commit, state }) => {
      return await axios
        .post(
          apiEndPoint + "/force-reset-school-items",
          {
            year: state.fiscalYear,
            loginUser: state.loginUser.school_id
          }
        )
        .then(res => {
          console.log(commit)
          console.log('res', res);
          return {
            data: res.data,
            status: res.status
          }
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    [RESET_SCHOOL_ITEMS]: async ({ commit, state }) => {
      return await axios
        .post(
          apiEndPoint + "/reset-school-items",
          {
            year: state.fiscalYear,
            loginUser: state.loginUser.school_id
          }
        )
        .then(res => {
          console.log(commit)
          console.log('res', res);
          return {
            data: res.data,
            status: res.status
          }
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    [CREATE_SCHOOL_ITEMS]: async ({ commit, state }) => {
      return await axios
        .post(
          apiEndPoint + "/create-school-items",
          {
            schoolItems: state.schoolItems.schoolItems,
            year: state.schoolItems.year,
            schoolId: state.loginUser.school_id,
            staffId: state.loginUser.staff_id,
          }
        )
        .then(res => {
          console.log(commit)
          console.log('res', res);
          return {
            data: res.data,
            status: res.status
          }
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    [UPDATE_SCHOOL_ITEMS]: async ({ commit, state }) => {
      return await axios
        .post(
          apiEndPoint + "/update-school-items",
          {
            schoolItems: state.schoolItems.schoolItems,
            year: state.schoolItems.year,
            loginUser: state.loginUser
          }
        )
        .then(res => {
          console.log(commit)
          console.log('res', res);
          return {
            data: res.data,
            status: res.status
          }
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    // 職員予約配信の更新
    [UPDATE_STAFF_DELIVERY]: async ({ commit, state }) => {
        return await axios
          .post(
            apiEndPoint + "/delivery/update-staff-delivery",
            {
              deliveryDetail: state.deliveryDetail,
              loginUser: state.loginUser,
              fiscal_year: state.fiscalYear
            }
          )
          .then(res => {
            console.log(commit)
            console.log('res', res);
            return res.data
          })
          .catch(err => {
            console.log("Error:", err)
          });
      },
    [UPDATE_DELIVERY]: async ({ commit, state }) => {
      return await axios
        .post(
          apiEndPoint + "/delivery/update-delivery",
          {
            deliveryDetail: state.deliveryDetail,
            loginUser: state.loginUser,
            fiscal_year: state.fiscalYear
          }
        )
        .then(res => {
          console.log(commit)
          console.log('res', res);
          return res.data
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    [DELETE_DELIVERY]: async ({ commit, state }) => {
      return await axios
        .post(
          apiEndPoint + "/delivery/delete-delivery",
          {
            deliveryDetail: state.deliveryDetail,
          }
        )
        .then(res => {
          console.log(commit)
          console.log('res', res);
          return res.data
        })
        .catch(err => {
          console.log("Error:", err)
        });
    },
    [SAVE_DELIVERY_IMG_TO_S3]: async ({ commit, state }) => { 
      if (state.deliveryImgs != null && state.deliveryImgs != "" && state.deliveryImgs != undefined) {
        for (let i = 0; i < state.deliveryImgs.length; i++) {
          if (state.deliveryImgs[i] != null && state.deliveryImgs[i] != "" && state.deliveryImgs[i] != undefined) {
            const apiUrl = apiEndPoint + '/delivery/get-presined';
            const body = { key: state.deliveryImgs[i].imgExe, methodType: "put" };
            console.log(commit)
            let presinedUrl = null;
            // presinedUrl取得処理
            await axios
              .post(apiUrl, body)
              .then((response) => {
                console.log("SAVE_DELIVERY_IMG_TO_S3", response);
                presinedUrl = response.data.body;
              })
              .catch((err) => {
                console.error(err);
              });

            if (presinedUrl == null) {
              return;
            }

            // s3に保存処理
            await fetch(presinedUrl, {
              method: "PUT",
              headers: {
                "Content-Type": state.deliveryImgs[i].imgExe,
              },
              body: state.deliveryImgs[i].imgObj,
            })
              .then((response) => {
                const pattern = /https:\/\/[^?]+/;
                const s3Url = response.url.match(pattern)[0];
                state.deliveryImgs[i].imgUrl = s3Url
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      } else {
        console.log('state.deliveryImgs is nullOrEmpty')
      }
    },
    [GET_DELIVERY_ALL_LENGTH]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/getDeliveryLists-length";

      const body = {
        authorityId: state.loginUser.authority,
        schoolId: state.loginUser.school_id,
        fiscal_year: state.fiscalYear,
      };
      // 学年主任権限なら、担当学年の情報を追加
      if (state.loginUser.authority === 3) {
        body.managedGrade = state.loginUser.managed_grade;
      }
      // 担任権限なら、担当クラスの情報を追加
      if (state.loginUser.authority === 4) {
        body.managedGrade = state.loginUser.managed_grade;
        body.managedClass = state.loginUser.managed_class;
      }
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response.data) {
            return response.data
          }
          return response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [GET_DELIVERY_ALL]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/getDeliveryLists";

      const body = {
        authorityId: state.loginUser.authority,
        schoolId: state.loginUser.school_id,
        fiscal_year: state.fiscalYear,
      };
      // 学年主任権限なら、担当学年の情報を追加
      if (state.loginUser.authority === 3) {
        body.managedGrade = state.loginUser.managed_grade;
      }
      // 担任権限なら、担当クラスの情報を追加
      if (state.loginUser.authority === 4) {
        body.managedGrade = state.loginUser.managed_grade;
        body.managedClass = state.loginUser.managed_class;
      }
      await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response.data) {
            commit(SET_DELIVERY_HISTORY_ITEMS, response.data.body);
            console.log(commit)
            console.log(state)
          }
          return response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [FETCH_REPLY_MESSAGES]: async ({ commit, state }, deliverId) => {
      const apiUrl = apiEndPoint + "/delivery/reply-messages" + `?deliveryId=${deliverId}`;
      return await axios
        .get(apiUrl)
        .then((response) => {
          return response.data.reply_messages;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [CHANGE_PASSWORD]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/dev/staff/change-password";
      const body = {
        newPassword: state.newPassword,
        loginUser: {
          school_id: state.loginUser.school_id,
          staff_id: state.loginUser.staff_id,
        }
      }
      const result = await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response.data.isLogin) {
            commit(SET_LOGIN_USER, response.data.staff);
            const loginInfoJson = localStorage.getItem('loginInfo');
            const loginInfo = JSON.parse(loginInfoJson);
            loginInfo.data.isLogged = true;
            localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
          }
          return response.data;
        })
        .catch((err) => {
          console.error(err);
        });
      return result;
    },
    // すべてのクラスルーム情報取得
    [GET_GRADE_CLASS_ALL]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/dev/delivery/get-grade-class-all";
      const body = {
        school_id: state.loginUser.school_id,
        fiscal_year: state.fiscalYear,
        loginUser: state.loginUser
      };

      await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response && response.data && response.data.body) {
            commit(SET_GRADE_CLASS_ALL, response.data.body);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 年度に紐づく全てのイベント情報を取得
    [GET_SCHEDULE_ITEMS]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/event/get-events-all";
      const body = {
        school_id: state.loginUser.school_id,
        year: state.fiscalYear,
        loginUser: state.loginUser
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response && response.data && response.data.body) {
            
            let obj = response.data.body.event_list
            for (let i=0; i < obj.length; i++) {
              obj[i].start = moment(obj[i].start_time).format('YYYY-MM-DD')
              obj[i].end = moment(obj[i].end_time).format('YYYY-MM-DD')
              obj[i].name = obj[i].event_name
              obj[i].timed = false
              switch (obj[i].target_division) {
                case 'SCHOOL':
                  obj[i].color = '#E17B9A'
                  break;
                case 'GRADE':
                  obj[i].color = '#EA733B'
                  break;
                case 'CLASS':
                  obj[i].color = '#4CAF93'
                  break;
                case 'PERSONAL':
                  obj[i].color = '#A1AF2E'
                  break;
              }
              obj[i].id = obj[i].event_id
            }
            commit(SET_SCHEDULE_ITEMS, obj);
      // commit(SET_SCHEDULE_ITEMS, [
      //   { id: 1, name: '運動会', start: moment('2023-06-11').toDate(), end: moment('2023-06-11').toDate(), color: 'orange' },
      //   { id: 2, name: '3者面談期間', start: moment('2023-06-21').toDate(), end: moment('2023-06-23').toDate(), color: 'blue' },
      //   { id: 3, name: '避難訓練', start: moment('2023-06-05').toDate(), end: moment('2023-06-05').toDate(), color: 'green' },
      //   { id: 4, name: '保護者説明会', start: moment('2023-06-30').toDate(), end: moment('2023-06-30').toDate(), color: 'orange' },
      // ]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [DELETE_SCHEDULE_ITEMS]: async ({ state }, event_id) => {
      const apiUrl = apiEndPoint + "/event/delete-event";
      const body = {
        school_id: state.loginUser.school_id,
        year: state.fiscalYear,
        loginUser: state.loginUser,
        event_id: event_id,
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log(response)
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [CREATE_SCHEDULE_ITEMS]: async ({ commit, state }, event) => {
      const apiUrl = apiEndPoint + "/event/create-event";
      console.log(commit)
      event.start = moment(event.start).add(9, 'h')
      event.end = moment(event.end).add(9, 'h')
      const body = {
        school_id: state.loginUser.school_id,
        year: state.fiscalYear,
        loginUser: state.loginUser,
        event: event,
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          // if (response && response.data && response.data.body) {
            console.log(response)
            // commit(SET_GRADE_CLASS_ALL, response.data.body);
          // }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 現在の学校情報取得
    [GET_CURRENT_SCHOOL]: async ({ commit, state }) => {
      const apiUrl = apiEndPoint + "/school/get-school-by-school-id";
      const body = {
        "school_id_list": [state.loginUser.school_id]
      };

      await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response && response.data && response.data.body) {
            const school_list = response.data.body;
            if (Array.isArray(school_list)) {
              commit(SET_CURRENT_SCHOOL, school_list[0]);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [UPDATE_SCHEDULE_ITEMS]: async ({ state }, event) => {
      const apiUrl = apiEndPoint + "/event/update-event";
      event.start = moment(event.start).add(9, 'h')
      event.end = moment(event.end).add(9, 'h')
      const body = {
        school_id: state.loginUser.school_id,
        year: state.fiscalYear,
        loginUser: state.loginUser,
        event: event,
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log(response)
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_USER_NAME]({ commit }, value) {
      commit(SET_USER_NAME, value);
    },
    [SAVE_USER_PASSWORD]({ commit }, value) {
      commit(SET_USER_PASSWORD, value);
    },
    [SAVE_DELIVERY_ITEM]({ commit }, value) {
      commit(SET_DELIVERY_ITEM, value);
    },
    [LOGIN_TRUE]({ commit }) {
      commit(CHANGE_LOGIN_STATUS, true);
    },
    [LOGIN_FALSE]({ commit }) {
      commit(CHANGE_LOGIN_STATUS, false);
    },
    [LOGGED_TRUE]({ commit }) {
      commit(CHANGE_LOGGED_STATUS, true);
    },
    [LOGGED_FALSE]({ commit }) {
      commit(CHANGE_LOGGED_STATUS, false);
    },
    [NOTIFICATION_TEAMS]: async ({ commit }, message) => {
      try {
        console.log(commit);
        const apiUrl = apiEndPoint + "/teams_notification";
        const body = {
          message: message
        }
        navigator.sendBeacon(apiUrl, JSON.stringify(body));

      } catch (error) {
        console.error(error);
      }
    },
    // アンケート機能
    [REGISTER_ENQUETE_OPEN_INFO]: async ({ commit, state }, payload) => {
      try {
        // commit(SET_IS_UPDATING_SURVEY_CONFIG, true);
        // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, null);
        const request = {
          enqueteOpenInfo: payload,
          loginUser: state.loginUser,
          fiscalYear: state.fiscalYear
        }

        const apiUrl = apiEndPoint + "/enquete/opne-info/put";
        const body = {
          body: request,
        };
        const response = await axios
          .post(apiUrl, body)
          .then((response) => {
            return response
          })
          .catch((err) => {
            console.error(err);
          });

        if (response.status == 200) {
          // commit(SET_REGISTERED_SURVEY_CONFIG_ID, response.data.item.surveyId);
          // commit(UPDATE_SURVEY_CONFIG_IN_STORE, response.data.item);
          return true;
        } else {
          // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, response.errorMessage);
          return false;
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, "この操作を行う場合は、権限を管理者にお問い合わせください");
        } else {
          // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, error.response.data);
        }
      } finally {
        // commit(SET_IS_UPDATING_SURVEY_CONFIG, false);
      }
    },
    [CLOSE_TO_PUBLIC_ENQUETE_OPEN_INFO]: async ({ commit, state }, payload) => {
      try {
        // commit(SET_IS_UPDATING_SURVEY_CONFIG, true);
        // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, null);
  
        const request = {
          enqueteOpenInfo: payload,
          loginUser: state.loginUser,
        }

        const apiUrl = apiEndPoint + "/enquete/opne-info/close-to-public";
        const body = {
          body: request,
        };
        const response = await axios
          .post(apiUrl, body)
          .then((response) => {
            return response
          })
          .catch((err) => {
            console.error(err);
          });

        if (response.status == 200) {
          // commit(SET_REGISTERED_SURVEY_CONFIG_ID, response.data.item.surveyId);
          // commit(UPDATE_SURVEY_CONFIG_IN_STORE, response.data.item);
          const array = [...state.enqueteOpenInfos];
          for (let i = 0; i < array.length; i++) {
            if (array[i].enqueteOpenId === payload.enqueteOpenId) {
              array[i].enqueteStatus = "2";
              break;
            }
          }
          console.log('array', array)
          commit(SET_ENQUETE_OPNE_INFOS, array);
          return true;
        } else {
          // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, response.errorMessage);
          return false;
        }
      } catch (error) {
        console.log(error.response.data);
        if (error.response.status === 403) {
          // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, "この操作を行う場合は、権限を管理者にお問い合わせください");
        } else {
          // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, error.response.data);
        }
      } finally {
        // commit(SET_IS_UPDATING_SURVEY_CONFIG, false);
      }
    },
    [FETCH_ENQUETE_OPNE_INFOS]: async ({ commit, state }, payload) => {
      try {
        // commit(SET_IS_UPDATING_SURVEY_CONFIG, true);
        // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, null);

        const apiUrl = apiEndPoint + "/enquete/opne-info/get-list";
        const body = {
          body: payload,
        };
        const response = await axios
          .post(apiUrl, body)
          .then((response) => {
            return response
          })
          .catch((err) => {
            console.error(err);
          });
  
        
        if (response.status == 200) {
          commit(SET_ENQUETE_OPNE_INFOS, response.data.items);
          return true;
        } else {
          // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, response.errorMessage);
          return false;
        }
      } catch (error) {
        console.log(error.response.data);
        // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, error.response.data);
      } finally {
        // commit(SET_IS_UPDATING_SURVEY_CONFIG, false);
      }
    },
    [GET_ENQUETE_OPNE_INFO]: async ({ commit, state }, payload) => {
      try {
        // commit(SET_IS_UPDATING_SURVEY_CONFIG, true);
        // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, null);

        const request = {
          enqueteOpenId: payload,
          schoolId: state.loginUser.school_id
        }

        const apiUrl = apiEndPoint + "/enquete/opne-info/get";
        const body = {
          body: request,
        };
        const response = await axios
          .post(apiUrl, body)
          .then((response) => {
            return response
          })
          .catch((err) => {
            console.error(err);
          });
  
        
        if (response.status == 200) {
          commit(SET_ENQUETE_OPNE_INFO, response.data.item);
          return true;
        } else {
          // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, response.errorMessage);
          return false;
        }
      } catch (error) {
        console.log(error.response.data);
        // commit(SET_UPDATE_SURVEY_CONFIG_ERROR, error.response.data);
      } finally {
        // commit(SET_IS_UPDATING_SURVEY_CONFIG, false);
      }
    },
    [GET_ANALYSIS_ENQUETE_ANSWER]: async ({ commit, state }, payload) => {
      try {

        const body = {
          enqueteOpenId: payload,
          schoolId: state.loginUser.school_id
        }

        const apiUrl = apiEndPoint + "/enquete/analysis";
        const response = await axios
          .post(apiUrl, body)
          .then((response) => {
            return response
          })
          .catch((err) => {
            console.error(err);
          });
  
        
        if (response.status == 200) {
          commit(SET_ANALYSIS_ENQUETE_ANSWER, response.data);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error.response.data);
      } finally {
        // commit(SET_IS_UPDATING_SURVEY_CONFIG, false);
      }
    },
    
    [SEND_ABSENCE_FORM]: async ({ state }) => {
      console.log('SEND_ABSENCE_FORM');
      const apiUrl = apiEndPoint + "/absence-form/send-absence-form";
      const body = {
        'absence_form': state.absenceFormToSend
      }
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          return response;
        })
        .catch((e) => console.error(e))
    },
    [GET_ABSENCE_FORM_ALL]: async ({ state, commit }) => {
      const apiUrl = apiEndPoint + "/absence-form/get-absence-form-all";
      const body = {
        school_id: state.loginUser.school_id,
        target_year: state.fiscalYear,
        authority: {
          authority_id: state.loginUser.authority
        }
      };
      // 学年主任権限なら、担当学年の情報を追加
      if (state.loginUser.authority === 3) {
        body.authority.managed_grade = state.loginUser.managed_grade;
      }
      // 学年主任権限なら、担当学年の情報を追加
      if (state.loginUser.authority === 4) {
        body.authority.managed_grade = state.loginUser.managed_grade;
        body.authority.managed_class = state.loginUser.managed_class;
      }

      await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response && response.data && response.data.body) {
            commit(SET_ABSENCE_FORM_ALL, response.data.body);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [GET_ABSENCE_FORM_ALL_LENGTH]: async ({ state, commit }) => {
      const apiUrl = apiEndPoint + "/absence-form/get-absence-form-all-length";
      const body = {
        school_id: state.loginUser.school_id,
        target_year: state.fiscalYear,
        authority: {
          authority_id: state.loginUser.authority
        }
      };
      // 学年主任権限なら、担当学年の情報を追加
      if (state.loginUser.authority === 3) {
        body.authority.managed_grade = state.loginUser.managed_grade;
      }
      // 学年主任権限なら、担当学年の情報を追加
      if (state.loginUser.authority === 4) {
        body.authority.managed_grade = state.loginUser.managed_grade;
        body.authority.managed_class = state.loginUser.managed_class;
      }

      await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response && response.data && response.data.body) {
            commit(SET_ABSENCE_FORM_ALL_LENGTH, response.data.body);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_DELIVERY_PDF_TO_S3]: async ({ commit, state }) => {
      if (state.deliveryPdfs != null && state.deliveryPdfs != "" && state.deliveryPdfs != undefined) {
        for (let i = 0; i < state.deliveryPdfs.length; i++) {
          if (state.deliveryPdfs[i] != null && state.deliveryPdfs[i] != "" && state.deliveryPdfs[i] != undefined) {
            const apiUrl = apiEndPoint + '/delivery/get-presined';
            const body = { key: state.deliveryPdfs[i].imgExe, methodType: "put" };
            console.log(commit)
            let presinedUrl = null;
            // presinedUrl取得処理
            await axios
              .post(apiUrl, body)
              .then((response) => {
                console.log("SAVE_DELIVERY_PDF_TO_S3", response);
                presinedUrl = response.data.body;
              })
              .catch((err) => {
                console.error(err);
              });

            if (presinedUrl == null) {
              return;
            }

            // s3に保存処理
            await fetch(presinedUrl, {
              method: "PUT",
              headers: {
                "Content-Type": state.deliveryPdfs[i].imgExe,
              },
              body: state.deliveryPdfs[i].imgObj,
            })
              .then((response) => {
                const pattern = /https:\/\/[^?]+/;
                const s3Url = response.url.match(pattern)[0];
                state.deliveryPdfs[i].imgUrl = s3Url
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      } else {
        console.log('state.deliveryPdfs is nullOrEmpty')
      }
    },


    [UPDATE_INITIALIZE_PASSWORD_STAFF]: async ({ state }, staff) => {
      const apiUrl = apiEndPoint + "/dev/staff/initialize-password";
      const body = {
        staff: staff,
        staff_id: staff.staff_id,
        loginUser: state.loginUser
      };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },


    // 生徒グループ一覧を取得
    [GET_STUDENT_GROUP_ALL]: async ({ state }) => {
      const apiUrl = apiEndPoint + "/student/get-studentgroup-all";
      const body = {
        school_id: state.loginUser.school_id,
        year: state.fiscalYear
      };
      return await axios
        .post(apiUrl, body)
        .then((response) => {
          if (response.data) {
            return response.data
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 生徒グループを削除
    [DELETE_STUDENT_GROUP]: async ({ state }, studentGroupId) => {
      const apiUrl = apiEndPoint + "/student/delete-studentgroup-all";
      const body = { school_id: state.loginUser.school_id, student_group_id: studentGroupId };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 生徒グループを更新
    [UPDATE_STUDENT_GROUP]: async ({ state }, studentGroup) => {
      const apiUrl = apiEndPoint + "/student/update-studentgroup-all";
      const body = { 
        school_id: state.loginUser.school_id,
        student_group: studentGroup,
        loginUser: state.loginUser
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 生徒グループを無効化
    [INVALID_STUDENT_GROUP]: async ({ state }, studentGroup) => {
      const apiUrl = apiEndPoint + "/student/invalid-studentgroup-all";
      const body = { 
        school_id: state.loginUser.school_id,
        student_group: studentGroup,
        loginUser: state.loginUser
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 生徒グループを作成
    [CREATE_STUDENT_GROUP]: async ({ state }, studentGroup) => {
      const apiUrl = apiEndPoint + "/student/create-studentgroup-all";
      const body = { 
        school_id: state.loginUser.school_id,
        student_group: studentGroup,
        loginUser: state.loginUser,
        year: state.fiscalYear
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
      },
    // 生徒グループに生徒を一括で追加
    [ADD_STUDENT_LIST_STUDENT_GROUP]: async ({ state }, obj) => {
      const apiUrl = apiEndPoint + "/student/add-student-list-student-group";
      const body = { 
        school_id: state.loginUser.school_id,
        student_group_id: obj.student_group_id,
        student_id_list: obj.student_id_list,
        loginUser: state.loginUser
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 生徒グループと生徒の紐づけを外す
    [DELETE_RELATION_STUDENT_GROUP]: async ({ state }, {student_group_id ,student_id}) => {
      const apiUrl = apiEndPoint + "/student/delete-relation-student-group";
      const body = { 
        school_id: state.loginUser.school_id,
        student_group_id: student_group_id,
        student_id: student_id,
        loginUser: state.loginUser
      };
      console.log('body', body)
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log('response', response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [FETCH_SCHOOL_LUNCH_FOOD_LIST]: async ({ commit }, { schoolId, fiscalYear }) => {
      const apiUrl = apiEndPoint + "/school-lunch/get-school-lunch-food-by-school-id-fiscal-year";
      await axios
        .post(apiUrl, {
          school_id: schoolId,
          fiscal_year: fiscalYear
        })
        .then((response) => {
          if (response && response.data && response.data.items) {
            commit(SET_SCHOOL_LUNCH_FOOD_LIST, response.data.items);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [FETCH_SCHOOL_LUNCH_MENU_INFO_LIST]: async ({ commit }, { schoolId, year, month }) => {
      const apiUrl = apiEndPoint + "/school-lunch/get-school-lunch-food-by-school-id-year-month";
      await axios
        .post(apiUrl, {
          school_id: schoolId,
          year: year,
          month: month
        })
        .then((response) => {
          if (response && response.data && response.data.items) {
            commit(SET_SCHOOL_LUNCH_MENU_INFO_LIST, response.data.items);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [FETCH_SCHOOL_LUNCH_ALLERGEN_INFO_LIST]: async ({ commit }, { schoolId }) => {
      const apiUrl = apiEndPoint + "/school-lunch/get-school-lunch-allergen-by-school-id";
      await axios
        .post(apiUrl, {
          school_id: schoolId
        })
        .then((response) => {
          if (response && response.data && response.data.items) {
            commit(SET_SCHOOL_LUNCH_ALLERGEN_INFO_LIST, response.data.items);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [FETCH_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST]: async ({ commit }, { schoolId, fiscalYear, authority, gradeIdManaged=null, classIdManaged=null }) => {
      const apiUrl = apiEndPoint + "/school-lunch/get-school-lunch-allergen-setting-status-by-school-id-authority";
      await axios
        .post(apiUrl, {
          school_id: schoolId,
          fiscal_year: fiscalYear,
          authority: authority,
          grade_id_managed: gradeIdManaged,
          class_id_managed: classIdManaged
        })
        .then((response) => {
          if (response && response.data && response.data.items) {
            commit(SET_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST, response.data.items);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [UPDATE_SCHOOL_LUNCH_ALLERGEN_SETTING]: async (_, { studentId, schoolLunchAllergenIdList, updatedBy }) => {
      const apiUrl = apiEndPoint + "/school-lunch/update-school-lunch-allergen-setting";
      return await axios
        .post(apiUrl, {
          student_id: studentId,
          school_lunch_allergen_id_list: schoolLunchAllergenIdList,
          updated_by: updatedBy
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  modules: {
    formEditor,
    forms,
  },
});
